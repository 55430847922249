export const LOAD_ALL_USER_FAILURE = 'LOAD_ALL_USER_FAILURE';
export const LOAD_ALL_USER_SUCCESS = 'LOAD_ALL_USER_SUCCESS';
export const INITIATE_LOADER_STATUS = 'INITIATE_LOADER_STATUS';
export const CHANGE_USER_MANAGE_MODAL_INPUT = 'CHANGE_USER_MANAGE_MODAL_INPUT';
export const OPEN_USER_MANAGE_MODAL_INPUT = 'OPEN_USER_MANAGE_MODAL_INPUT';
export const CLOSE_USER_MANAGE_MODAL_INPUT = 'CLOSE_USER_MANAGE_MODAL_INPUT';
export const SUBMIT_USER_FAILURE = 'SUBMIT_USER_FAILURE';
export const SUBMIT_USER_SUCCESS = 'SUBMIT_USER_SUCCESS';

export const GET_USER_ACCOUNT_CHANGE_LOG_FAILURE = 'GET_USER_ACCOUNT_CHANGE_LOG_FAILURE';
export const GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS = 'GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS';

export const GET_USER_ACCOUNT_ACCESS_LOG_FAILURE = 'GET_USER_ACCOUNT_ACCESS_LOG_FAILURE';
export const GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS = 'GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS';


export const GET_PM_MANAGE_LIST_FAILURE = 'GET_PM_MANAGE_LIST_FAILURE';
export const GET_PM_MANAGE_LIST_SUCCESS = 'GET_PM_MANAGE_LIST_SUCCESS';