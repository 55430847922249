const cookie = {
    //根据key值获取对应的cookie
    getCookie(key) {
      const data = document.cookie
      let startIndex = data.indexOf(key + '=')
      if (startIndex > -1) {
        startIndex = startIndex + key.length + 1
        let endIndex = data.indexOf(';', startIndex)
        endIndex = endIndex < 0 ? data.length : endIndex
  
        return decodeURIComponent(data.substring(startIndex, endIndex))
      } else {
        return ''
      }
    },
  
    setCookie(key, value, time) {
      const times = time
      const cur = new Date()
      cur.setTime(cur.getTime() + times * 24 * 3600 * 1000)
      const domain='';
      key = encodeURIComponent(key);
      const exp = (times === undefined ? '' : cur.toUTCString());
      document.cookie = `${key}=${value};domain=${domain};expires=${exp}`
    },
  
    delCookie(key) {
      const data = this.getCookie(key)
      const domain='';
      const d = new Date();
      d.setTime(d.getTime() - 1);
      if (data !== false) {
        document.cookie = `${key}=${data};domain=${domain};expires=${d.toGMTString()}`
      }
    }
  }
  
  export default cookie