import React, { Component } from 'react';
import { Tabs } from 'antd';

import ExternalSourceGrid from './ExternalSourceGrid'
import BrokerSourceGrid from './BrokerSourceGrid'
import {
  isAdminRole
} from 'common/utils/DomainUtils';
import './UserManageDashboard.css';

const TabPane = Tabs.TabPane;
class UserManageDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
  }

  tabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  isAdmin = () => {
    const { user } = this.props;
    return isAdminRole(user.role);
  }

  render() {
    return (
      <div className="userMangerDashboardWrapper">
        <Tabs
          tabPosition={'top'}
          onChange={this.tabChange}
          activeKey={this.state.activeTab}
        >
         
        <TabPane tab="External Source" key="1">
            <ExternalSourceGrid {...this.props} />
        </TabPane>
        <TabPane tab="Broker Source" key="2">
            <BrokerSourceGrid {...this.props} />
        </TabPane>
        </Tabs>
        
      </div>
    );
  }
}

export default UserManageDashboard;
