const config = {
  dev: {
    api: {
      LOGIN_URL: 'http://portaldev.pinpointfund.com:8330',
      AUTH_URL: 'http://portaldev.pinpointfund.com:8330',
      RESEARCH_URL: 'http://portaldev.pinpointfund.com:8500/api',
      EXTERNAL_URL: 'http://pms.pinpointfund.com/',

      //AUTH_URL: '/auth'
    },
    env: 'dev',
    title: 'Portal-DEV'
  },
  'qa-sh': {
    api: {
      LOGIN_URL: '/login',
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'qa',
    system: 'ONSHORE',
    title: 'Portal-QA'
  },
  'qa-hk': {
    api: {
      LOGIN_URL: '/login',
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'qa',
    system: 'OFFSHORE',
    title: 'Portal-QA'
  },
  'prod-sh': {
    api: {
      LOGIN_URL: '/login',
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'prod',
    system: 'ONSHORE',
    title: 'PINPOINT-PORTAL'
  },
  'prod-hk': {
    api: {
      LOGIN_URL: '/login',
      AUTH_URL: '/auth',
      RESEARCH_URL: '/rsrch',
      EXTERNAL_URL: '/pms',
    },
    env: 'prod',
    system: 'OFFSHORE',
    title: 'PINPOINT-PORTAL'
  }
}[process.env.REACT_APP_ENV || 'dev'];

export default config;
