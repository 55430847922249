import {
    LOAD_ALL_APPLICATION_FAILURE,
    LOAD_ALL_APPLICATION_SUCCESS,
    OPEN_APPLICATION_MANAGE_MODAL_INPUT,
    CLOSE_APPLICATION_MANAGE_MODAL_INPUT,
    INITIATE_LOADER_STATUS,
    ADD_ROLE_APPLICATION_CONFIG_SUCCESS,
    ADD_ROLE_APPLICATION_CONFIG_FAILURE,
    GET_USER_ACCOUNT_FAILURE,
    GET_USER_ACCOUNT_SUCCESS
} from './applicationManageConstants';
import client from './api/client';

export function initiateLoaderStatus() {
    return {
      type: INITIATE_LOADER_STATUS
    };
  }

function getAllApplicationSuccess(data) {
    return {
      type: LOAD_ALL_APPLICATION_SUCCESS,
      payload: data
    };
  }
  
  function getAllApplicationFailure(err) {
    return {
      type: LOAD_ALL_APPLICATION_FAILURE,
      payload: err
    };
  }
  
  export function getAllApplication(params) {
    return function(dispatch) {
      dispatch(initiateLoaderStatus())
      client
        .getAllApplication(params)
        .then(resp => dispatch(getAllApplicationSuccess(resp)))
        .catch(err => dispatch(getAllApplicationFailure(err)));
    };
  }

export function openApplicationManageControlModal() {
    return {
        type: OPEN_APPLICATION_MANAGE_MODAL_INPUT
      };
}

export function closeApplicationManageControlModal() {
    return {
        type: CLOSE_APPLICATION_MANAGE_MODAL_INPUT
      };
}

function addRoleApplicationConfigSuccess(data) {
  return {
    type: ADD_ROLE_APPLICATION_CONFIG_SUCCESS,
    payload: data
  };
}

function addRoleApplicationConfigFailure(err) {
  return {
    type: ADD_ROLE_APPLICATION_CONFIG_FAILURE,
    payload: err
  };
}

export function addRoleApplicationConfig(params) {
  return function(dispatch) {
    client
      .addRoleApplicationConfig(params)
      .then(resp => {
        dispatch(addRoleApplicationConfigSuccess(resp));
        dispatch(getAllApplication({queryUserMode: false}));
      })
      .catch(err => dispatch(addRoleApplicationConfigFailure(err)));
  };
}

function getUserAccountListSuccess(data) {
  return {
    type: GET_USER_ACCOUNT_SUCCESS,
    payload: data
  };
}

function getUserAccountListFailure(err) {
  return {
    type: GET_USER_ACCOUNT_FAILURE,
    payload: err
  };
}

export function getUserAccountList(params) {
  return function(dispatch) {
    client
      .getUserAccountList(params)
      .then(resp => {
        dispatch(getUserAccountListSuccess(resp));
      })
      .catch(err => dispatch(getUserAccountListFailure(err)));
  };
}