import { connect } from 'react-redux';

import HomePageDashboard from '../components/HomePageDashboard';
import { selectSettings } from '../../settings/settingSelectors';
import {
} from '../homePageActions';

const mapStateToProps = state => {
  const user = selectSettings(state).user;
  const settings = selectSettings(state);
  return {
    user,
    settings
  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePageDashboard);
