import { connect } from 'react-redux';

import LoginForm from '../components/LoginForm';
import { login, resetLoginForm } from '../authActions';

function mapStateToProps(state) {
  return {
    loginStatus: state.auth.loginStatus,
    user: state.settings.user,
    errorMsg: state.auth.errorMsg
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: user => dispatch(login(user)),
    resetForm: () => dispatch(resetLoginForm())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
