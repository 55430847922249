import { connect } from 'react-redux';

import SourceManageDashboard from '../components/SourceManageDashboard';

import {

  
  getPmManageList,
  loadExternalSource,
  loadBrokerSource,
  changeExternalSourceControlModalInput,
  openExternalSourceControlModal,
  closeExternalSourceControlModal,
  submitExternalSource,
} from '../sourceManageActions';

const mapStateToProps = state => {
  const user = state.settings.user;

  return {
    user,
    isLoaded: state.sourceManage.isLoaded,
    isBrokerLoaded: state.sourceManage.isBrokerLoaded,
    allExternalSource: state.sourceManage.allExternalSource,
    externalSourceListColumn: state.sourceManage.externalSourceListColumn,
    userAccountOptions: state.sourceManage.userAccountOptions,
    externalSourceControlModal: state.sourceManage.externalSourceControlModal,
    externalSourceQueryParams: state.sourceManage.externalSourceQueryParams,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    loadExternalSource: params => dispatch(loadExternalSource(params)),
    loadBrokerSource: params => dispatch(loadBrokerSource(params)),
    changeExternalSourceControlModalInput: input => dispatch(changeExternalSourceControlModalInput(input)),
    openExternalSourceControlModal: () => dispatch(openExternalSourceControlModal()),
    closeExternalSourceControlModal: () => dispatch(closeExternalSourceControlModal()),
    submitExternalSource: (params, queryParams) => dispatch(submitExternalSource(params, queryParams)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceManageDashboard);
