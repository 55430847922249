import React, { Component } from 'react';
import {
    Modal,
    Button,
    Tree
  } from 'antd';
import _ from 'lodash'

class RolePermissionsDialog extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    _closeModal = () => {
      this.props.closePortfolioPlanDialog();
    };

    _onSubmit = () => {
      this.props.onSubmit();
    }

    _getCheckKeys = (permissions, permissionsCheckKeys) => {
      if(_.isEmpty(permissions)) return[];
      if(_.isEmpty(permissionsCheckKeys)) permissionsCheckKeys = [];
      const appPermissions = [];
      const checkKeysMap = {};
      permissionsCheckKeys.forEach(r => {checkKeysMap[r] =r});
      for (let i=0;i<permissions.length;i++){ 
        const permission = permissions[i];
        for (let j=0;j<permission.children.length;j++){ 
          const key = permission.children[j].key;
          if(checkKeysMap[key]) {
            appPermissions.push(permission.key);
            break;
          }
        }
      }
      permissionsCheckKeys.push(...appPermissions);
      return permissionsCheckKeys;
    }

    render() {
        const { closeModal, modalData, onTreeCheck } = this.props;
        const { isOpened, permissions, checkedKeys } = modalData;
        const checkKeys = this._getCheckKeys(permissions,
          checkedKeys);
        return (
            <Modal
            size="large"
            visible={isOpened}
            title={`Manage Role Permissions`}
            width={1300}
            style={{ height: 500 }}
            footer={[
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this._onSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            ]}
            closable={false}
          >
            <div style={{height:'400px', overflow:'auto'}}>
            <Tree
              checkable
              autoExpandParent={true}
              checkedKeys={checkKeys}
              treeData={permissions}
              checkStrictly={true}
              onCheck={onTreeCheck}
            />
            </div>
          </Modal>
        );
    }
}

export default RolePermissionsDialog;