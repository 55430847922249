import { TOGGLE_SIDEBAR } from './layoutConstants';
import { createReducer } from 'common/utils/reducerUtils';
import _ from 'lodash';

const _isMobile = () => window.matchMedia('(max-width: 768px)').matches;
const _showNavBar = () => {
  let url = window.location.href
  if(_.endsWith(url, 'userManger')||_.endsWith(url, 'applicationManage')){
    return true;
  }
  return false;
}

const _showScoreNavBar = () => {
  let url = window.location.href
  if(_.endsWith(url, 'scoreSystem')){
    return true;
  }
  return false;
}
const initialState = {
  isSidebarOpen: false,
  showSideNavBar: _showNavBar(),
  showScoreNavBar: _showScoreNavBar(),
  isMobile: _isMobile()
};

export function toggleSidebar(state, payload) {
  return {
    ...state,
    isSidebarOpen: !state.isSidebarOpen
  };
}

export default createReducer(initialState, {
  [TOGGLE_SIDEBAR]: toggleSidebar
});
