import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { permissionManageListColumn } from './GridColumnMap';
import client from '../api/client';

class PermissionMagagerListGrid extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          columnDefs: permissionManageListColumn,
          defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50,
            width: 130,
            sortable: true,
            filter: true,
            resizable: true
          },
          context: {
            gridComponent: this
          },
          autoGroupColumnDef: [{
            headerName: 'Manager',
            field: 'manager',
            cellClass: 'keyword',
            width: 230
          },{
            headerName: 'Fund',
            field: 'fundCode',
            cellClass: 'keyword',
            width: 230
          }],
          groupDefaultExpanded: 0,
          rowGroupPanelShow: 'always',
          pivotPanelShow: 'always',
          sideBar: {
            toolPanels: ['columns', 'filters']
          },
          data: []
        };
    }

    async componentDidMount(){
        this.getData({});
    }

    getData = async (params) => {
      const data = await client.getPermissionList(params);
      if(data && data.data){
        this.setState({
          data: data.data
        })
      }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        this.gridApi.sizeColumnsToFit();
    };

    render(){
        const { className = 'grid-wrapper' } = this.props;
        const { data } = this.state;
        
        return (
            !_.isEmpty(data) && <div style={{height:'100%'}}>
                <div className={`ag-theme-balham-dark ${className}`} style={{height:'90%'}}>
                    <AgGridReact
                      // properties
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      rowData={data}
                      suppressAggFuncInHeader={true}
                      animateRows={true}
                      autoGroupColumnDef={this.state.groupColumnDef}
                      enableRangeSelection={true}
                      rowGroupPanelShow={this.state.rowGroupPanelShow}
                      groupDefaultExpanded={this.state.groupDefaultExpanded}
                      pivotPanelShow={this.state.pivotPanelShow}
                      rowSelection="single"
                      context={this.state.context}
                      sideBar={this.state.sideBar}
                      onGridReady={this.onGridReady}
                    />
                </div>
        </div>);
    }

}

export default PermissionMagagerListGrid;