import { connect } from 'react-redux';

import ScoreDashboard from '../components/ScoreDashboard';

import {
  // Broker Score
  loadBrokerScoreListByScore,
  loadBrokerScoreList,
  saveBrokerScore,
  removeBrokerScore,
  loadCorrespondenceList,
  initiateWeightMapLoaded,
  changeBrokerQureyParams,
  changeBrokerScoreControlModalInput, 
  openBrokerScoresDetailDialog,
  resetBrokerDetailControlModal,
} from '../scoreActions';

const mapStateToProps = state => {
  const user = state.settings.user;

  return {
    user,
    isLoaded: state.scoreSystem.isLoaded,
     // Broker Score
     brokerScoreGridColumns: state.scoreSystem.brokerScoreGridColumns,
     brokerScores: state.scoreSystem.brokerScores,
     brokerControlModal: state.scoreSystem.brokerControlModal,
     brokerScoreControlModal: state.scoreSystem.brokerScoreControlModal,
     brokerScoreYearControlModal: state.scoreSystem.brokerScoreYearControlModal,
     brokerScoreListForDetail: state.scoreSystem.brokerScoreListForDetail,
     brokerQueryParams: state.scoreSystem.brokerQueryParams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadBrokerScoreListByScore: queryParams =>
      dispatch(loadBrokerScoreListByScore(queryParams)),
    loadBrokerScoreList: queryParams =>
      dispatch(loadBrokerScoreList(queryParams)),
    saveBrokerScore: (params, queryParams) =>
      dispatch(saveBrokerScore(params, queryParams)),
    removeBrokerScore: (params, queryParams) =>
      dispatch(removeBrokerScore(params, queryParams)),
    openBrokerScoresDetailDialog: votingMonth =>
      dispatch(openBrokerScoresDetailDialog(votingMonth)),
    loadCorrespondenceList: params => dispatch(loadCorrespondenceList(params)),
    initiateWeightMapLoaded: () => dispatch(initiateWeightMapLoaded()),
    changeBrokerQureyParams: input => dispatch(changeBrokerQureyParams(input)),
    changeBrokerScoreControlModalInput: input =>
      dispatch(changeBrokerScoreControlModalInput(input)),
    resetBrokerDetailControlModal: () =>
      dispatch(resetBrokerDetailControlModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoreDashboard);
