import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Grid, Header, Segment, Message } from 'semantic-ui-react';
import {localStorageClient} from "common/api/localStorageClient"
import Field from 'common/ui/Field';
import {Input,Select,Divider} from "antd"
import cookie from 'common/utils/CookieUtil';
import _ from 'lodash';
import client from '../api/client'
import './LoginForm.css';

const { Option } = Select;
class LoginForm extends Component {

  constructor(props) {
    super(props);

    const { englishName: name = '' } = props.user || {};
    this.state = {
      fields: {
        name,
        pwd: '',
        expirePeriod: 'TWO_HOUR'
      },
      fieldErrors: {},
      isShowCode: false,
      time: 59,
      errors:[],
      options:[
        {'name': 'Temporary','value': 'TWO_HOUR'},
        {'name': '1 day','value': 'DAY'},
        {'name': '1 month','value': 'ONE_MONTH'},
        {'name': '3 months','value': 'THREE_MONTH'}],
    };
  }

  componentDidMount(){
    const lastPage = document.referrer;
    localStorageClient.save('lastPage', lastPage)
  }

  onFormSubmit = evt => {
    const user = this.state.fields;
    evt.preventDefault();

    if (this.validate()) return;
    if(!this.validateAuthCode()) return;
    this.props.onSubmit(user);
  };

  onInputChange = ({ name, value, error }) => {
    const user = this.state.fields;
    const fieldErrors = this.state.fieldErrors;

    this.setState({
      fields: { ...user, [name]: value },
      fieldErrors: { ...fieldErrors, [name]: error }
    });
  };

  validate = () => {
    const user = this.state.fields;
    const fieldErrors = this.state.fieldErrors;
    const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

    if (!user.name) return true;
    if (!user.pwd) return true;
    if (errMessages.length) return true;

    return false;
  };

  validateAuthCode = () =>{
    const user = this.state.fields;
    if(!this.isVerifyMachine()){
      const errors = [];
      if(_.isEmpty(user.authCode)){
        errors.push("Auth code must not null");
      }
      this.setState({
        errors
      });
      if(errors.length>0){
        return false;
      }
    }
    return true;
  }

  getAuthCode = async() =>{
    const { isShowCode } = this.state;
    const user = this.state.fields;
    if (!this.checkGetAuthCodeField() || isShowCode) { // 倒计时未结束,不能重复点击
      return
    }
    client.getLoginAuthCode({'englishName': user.name,'expirePeriod': user.expirePeriod})
    .then(response=>{
      if(response && response.code !== '0'){
        const errors = [];
        errors.push(response.msg);
        this.setState({
          errors
        });
      }else if(response && response.code === '0'){
        this.setState({
          isShowCode: true
        });
        // 倒计时
        const active = setInterval(() => {
          const { time } = this.state;
          let currentTime = this.getTime(time,active)
          this.setState({
            time: currentTime
          })
        }, 1000)
      }
    });
    
  }

  checkGetAuthCodeField = () => {
    const user = this.state.fields;
    const errors = [];
    if(_.isEmpty(user.name)){
      errors.push('Name must not null');
    }
    this.setState({
      errors
    })
    if(errors.length>0){
      return false;
    }
    return true;
  }

  getTime = (preSecond,active) => {
    if (preSecond <= 1) {
      this.setState({
        isShowCode: false
      })
      clearInterval(active)
      // 重置秒数
      return 59
    }
    return preSecond - 1
  }

  jumpToVerifyMachine = () =>{
    const domain = document.domain;
    const protocol = window.location.protocol;
    window.location = `${protocol}//${domain}/machineReg`
  }

  isVerifyMachine = (userName)=>{
    let machineCode = cookie.getCookie('machineCode');
    let machineUser = cookie.getCookie('machineUser');
    if(!_.isEmpty(machineCode) && !_.isEmpty(machineUser)){
      if(!_.isEmpty(userName) && userName !== machineUser){
        return false;
      }
      return true;
    }
  }

  onInputChangeEventHandle = (event, fieldName) => {
    const filedValue = event.target.value;
    const user = this.state.fields;
    this.setState({
      fields: { ...user, [fieldName]: filedValue }
    });
 }

 onValueChangeHandle = (value,name) =>{
  const user = this.state.fields;
  this.setState({
    fields: { ...user, [name]: value }
  });
}

  render() {
    let status = this.props.loginStatus;
    const{ isShowCode,time,errors } = this.state;
    const{ errorMsg } = this.props;
    if(!_.isEmpty(errorMsg) && errors && errors.indexOf(errorMsg) < 0){
      errors.push(errorMsg);
    }
    if (status === 'SUCCESS') {
      this.props.resetForm();
      return <Redirect to="/" />;
    } else {
      return (
        <Grid
          textAlign="center"
          style={{ height: '100%', width: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="grey" textAlign="center">
              Log-in to your account
            </Header>

            <br />

            <Form onSubmit={this.onFormSubmit}>
              <Segment.Group stacked>
                <Segment>
                  <Field
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={this.state.fields.name}
                    error={this.state.fieldErrors.name}
                    onChange={this.onInputChange}
                    validate={val => (val ? false : 'Name is required')}
                  />
                </Segment>
                <Segment>
                  <Field
                    type="password"
                    placeholder="Password"
                    name="pwd"
                    value={this.state.fields.pwd}
                    error={this.state.fieldErrors.pwd}
                    onChange={this.onInputChange}
                    validate={val => (val ? false : 'Password is required')}
                  />
                </Segment>
                {this.isVerifyMachine()?(""):
                  (
                    <Segment>
                      <fieldset style={{border:'1px solid silver'}}>
                        <legend align="center" style={{fontSize:'14px',border:0,width: 'auto'}}>This equipment is not authenticated</legend>
                          <Select 
                            style={{width:'100%'}}
                            allowClear={false} 
                            defaultValue={this.state.fields.expirePeriod}
                            onChange={(value) => {
                              this.onValueChangeHandle(value, 'expirePeriod');
                            }}>
                              {this.state.options.map(item => {
                                return (<Option key={item.value} value={item.value}>{item.name}</Option>);
                              })}
                          </Select>
                          <Input 
                            placeholder="Input your received auth code"
                            style={{marginTop:'10px', border:0, padding:0}}
                            onChange={e=>{
                              this.onInputChangeEventHandle(e,'authCode');
                            }}
                            suffix={<a onClick={() => this.getAuthCode()}>
                            {isShowCode ? `Resend in ${time} seconds` : 'Send auth code'}
                          </a>}
                       />
                      </fieldset>
                    </Segment>
                  )
                }
              
                <Segment>
                  {
                    {
                      LOGINING: (
                        <Button
                          type="submit"
                          color="green"
                          disabled
                          size="tiny"
                        >
                          Login...
                        </Button>
                      ),
                      SUCCESS: (
                        <Button
                          type="submit"
                          color="green"
                          disabled
                          size="tiny"
                        >
                          Login Success
                        </Button>
                      ),
                      ERROR: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Login Fail - Retry?
                        </Button>
                      ),
                      READY: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Submit
                        </Button>
                      )
                    }[status]
                  }
                </Segment>
              </Segment.Group>
            </Form>
            {_.isEmpty(errors)?(""):
                    (
                      <Message error list={errors} style={{ marginBottom: '3px' }} />
                    )                
            }
          </Grid.Column>
        </Grid>
      );
    }
  }
}

export default LoginForm;
