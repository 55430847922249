import EntityMap from 'entities/EntityMap';
import {
    dateFormatter,
    dateTimeFormatter
  } from 'common/utils/valueFormatters';

  export const externalSourceListColumn = [
    {
        field: 'sourceId',
        headerName: 'sourceId',
        cellClass: 'non-number',
        // enableRowGroup: true,
        // autoHeight: true,
        // rowGroup: true,
        hide: true
    },
    {
        field: 'sourceName',
        headerName: 'SourceName',
        cellClass: 'non-number',
        width: 80,
        // enableRowGroup: true,
        // autoHeight: true,
        // rowGroup: true,
        // hide: true
    },
    {
        field: 'sourceType',
        headerName: 'SourceType',
        cellClass: 'non-number',
        enableRowGroup: true,
        rowGroup: true,
        width: 50,
    },

    // {
    //     field: 'ownerType',
    //     headerName: 'Access',
    //     cellClass: 'non-number',
    //     enableRowGroup: true,
    //     rowGroup: true,

    // },
    {
        field: 'costDesc',
        headerName: 'CostDesc',
        cellClass: 'non-number',
        width: 40,
    },
    // {
    //     field: 'purchaseAmount',
    //     headerName: 'Amount',
    //     cellClass: 'non-number',
    // },
    // {
    //     field: 'purchaseDesc',
    //     headerName: 'Desc',
    //     cellClass: 'non-number',
    // },
    {
        field: 'sourceDesc',
        headerName: 'SourceDesc',
        cellClass: 'non-number',
        width: 400,
    },
    {
        field: 'purchaseDate',
        headerName: 'PurchaseDate',
        cellClass: 'non-number',
        width: 50,
    },
    {
        field: 'expireDate',
        headerName: 'ExpireDate',
        cellClass: 'non-number',
        width: 50,
    },
    {
        field: 'userAccountName',
        headerName: 'Contact Person',
        cellClass: 'non-number',
        width: 50,
        enableRowGroup: true,
    },
  ];
  export const brokerSourceListColumn = [


    {
        field: 'brokerType',
        headerName: 'BrokerType',
        cellClass: 'non-number',
        enableRowGroup: true,
        rowGroup: true,
        width: 80,
    },

    {
        field: 'brokerName',
        headerName: 'BrokerName',
        cellClass: 'non-number',
        enableRowGroup: true,
        width: 80,

    },
    // {
    //     field: 'pbFunds',
    //     headerName: 'PbFunds',
    //     cellClass: 'non-number',
    // },
    {
        field: 'market',
        headerName: 'Market',
        cellClass: 'non-number',
        width: 50,
    },
    {
        field: 'contact',
        headerName: 'contact',
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'contactWeChat',
        headerName: 'contactWeChat',
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'contactMail',
        headerName: 'contactMail',
        cellClass: 'non-number',
        width: 130,
    },
    {
        field: 'rrplatform',
        headerName: 'RRPlatform',
        cellClass: 'non-number',

    },
    {
        field: 'miniProgram',
        headerName: 'MiniProgram',
        cellClass: 'non-number',
        width: 100,
    },

    {
        field: 'resourceMode',
        headerName: 'ResourceMode',
        cellClass: 'non-number',

    },
    {
        field: 'regularMeeting',
        headerName: 'RegularMeeting',
        cellClass: 'non-number',

    },
  ];
//   export const userManageGridColumnMap = EntityMap.fromArray(
//     // userManageGridColumns,
//     'field'
//   );