// server error message
export const INITIATE_LOADER_STATUS = 'INITIATE_LOADER_STATUS';
export const SERVER_ERROR_MESSAGE =
  'Server Error! Please contact system administrator.';

// Broker Score
export const LOAD_BROKER_SCORES_SUCCESS = 'LOAD_BROKER_SCORES_SUCCESS';
export const LOAD_BROKER_SCORES_FAILURE = 'LOAD_BROKER_SCORES_FAILURE';

export const DELETE_BROKER_SCORES_SUCCESS = 'DELETE_BROKER_SCORES_SUCCESS';
export const DELETE_BROKER_SCORES_FAILURE = 'DELETE_BROKER_SCORES_FAILURE';

export const BROKER_SCORES_SUBMIT_REQUEST = 'BROKER_SCORES_SUBMIT_REQUEST';
export const BROKER_SCORES_SUBMIT_SUCCESS = 'BROKER_SCORES_SUBMIT_SUCCESS';
export const BROKER_SCORES_SUBMIT_FAILURE = 'BROKER_SCORES_SUBMIT_FAILURE';

export const OPEN_BROKER_SCORES_NEW_DIALOG = 'OPEN_BROKER_SCORES_NEW_DIALOG';
export const OPEN_BROKER_SCORES_UPDATE_DIALOG =
  'OPEN_BROKER_SCORES_UPDATE_DIALOG';
export const BROKER_SCORES_INPUT_CHANGE = 'BROKER_SCORES_INPUT_CHANGE';

export const NEW_BROKER_SCORE_ROW_DATA = 'NEW_BROKER_SCORE_ROW_DATA';

export const OPEN_BROKER_SCORES_DETAIL_DIALOG =
  'OPEN_BROKER_SCORES_DETAIL_DIALOG';
export const OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG =
  'OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG';
export const RESET_BROKER_SCORES_DETAIL_DIALOG =
  'RESET_BROKER_SCORES_DETAIL_DIALOG';
export const RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG =
  'RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG';
export const LOAD_BROKER_SCORE_BY_BROKER_SUCCESS =
  'LOAD_BROKER_SCORE_BY_BROKER_SUCCESS';
export const LOAD_BROKER_SCORE_BY_BROKER_FAILURE =
  'LOAD_BROKER_SCORE_BY_BROKER_FAILURE';
export const LOAD_BROKER_SCORE_LIST_SUCCESS = 'LOAD_BROKER_SCORE_LIST_SUCCESS';
export const LOAD_BROKER_SCORE_LIST_FAILURE = 'LOAD_BROKER_SCORE_LIST_FAILURE';
export const SAVE_BROKER_SCORE_SUCCESS = 'SAVE_BROKER_SCORE_SUCCESS';
export const SAVE_BROKER_SCORE_FAILURE = 'SAVE_BROKER_SCORE_FAILURE';
export const REMOVE_BROKER_SCORE_SUCCESS = 'REMOVE_BROKER_SCORE_SUCCESS';
export const REMOVE_BROKER_SCORE_FAILURE = 'REMOVE_BROKER_SCORE_FAILURE';
export const LOAD_CORRESPONDENCE_LIST_SUCCESS =
  'LOAD_CORRESPONDENCE_LIST_SUCCESS';
export const LOAD_CORRESPONDENCE_LIST_FAILURE =
  'LOAD_CORRESPONDENCE_LIST_FAILURE';
export const INITIATE_WEIGHT_MAP_LOADER_STATUS =
  'INITIATE_WEIGHT_MAP_LOADER_STATUS';
export const CHANGE_BROKER_QUERY_PARAMS = 'CHANGE_BROKER_QUERY_PARAMS';
export const CHANGE_BROKER_SCORE_MODAL_INPUT =
  'CHANGE_BROKER_SCORE_MODAL_INPUT';