import React, { Component } from 'react';
import 'moment/locale/zh-cn';
import { Row, Col, Button,Select } from 'antd';
import { Header, Modal,Message } from 'semantic-ui-react';

const { Option } = Select;
class RoleApplicationManageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      roleList: [
        {'name': 'Admin','value': 'Admin'},
        {'name': 'Analyst','value': 'Analyst'},
        {'name': 'BD','value': 'BD'},
        {'name': 'Compliance','value': 'Compliance'},
        {'name': 'CustomRelationship','value': 'CustomRelationship'},
        {'name': 'Economist','value': 'Economist'},
        {'name': 'FundMngr','value': 'FundMngr'},
        {'name': 'IT','value': 'IT'},
        {'name': 'Marketing','value': 'Marketing'},
        {'name': 'Operation','value': 'Operation'},
        {'name': 'Supervisor','value': 'Supervisor'},
        {'name': 'Trader','value': 'Trader'},
        {'name': 'Risk','value': 'Risk'},
      ],
      applicationList: [
        {'value': 'IMS','name': 'IMS'},
        {'value': 'userManage','name': 'User Manage'},
          {'value': 'scoreSystem','name': 'Score System'},
        {'value': 'CMS','name': 'CMS Admin'},
        {'value': 'PMS','name': 'PMS'},
        {'value': 'PRS','name': 'PRS'},
        {'value': 'PETS','name': 'PETS'},
        {'value': 'CRM','name': 'CRM'},
        {'value': 'Marking','name': 'Marking'},
        {'value': 'WIKI','name': 'WIKI'},
        {'value': 'JIRA','name': 'JIRA'},
        {'value': 'beauchamp','name': 'Beauchamp'},
        {'value': 'reporting','name': 'Reporting'},
        {'value': 'EAM','name': 'EAM'},
      ],
    };
  }

  componentDidMount(){
    this.props.getUserAccountList();
  }

  saveData = () =>{
    this.props.addRoleApplicationConfig(this.state.data)
  }

  onValueChangeHandle = (value, name) =>{
    const {data} = this.state;
    const updateData = {
      ...data,
      [name]: value
    }
    this.setState({
      data: updateData
    });
  }

  _createForm = () =>{
    const { innerUserAccountList } = this.props;
    return (
      <div>
        <Row>
          <Col span={24}>
            <Select 
                mode="multiple"
                style={{width: '300px'}}
                placeholder="please select user"
                optionFilterProp="key"
                onChange={(value) => {
                  this.onValueChangeHandle(value, 'userAccountIdList');
                }}>
                {innerUserAccountList.map(item => {
                  return (<Option key={item.englishName} value={item.userAccountId}>{item.englishName}</Option>);
                })}
              </Select>
          </Col>
        </Row>
        <Row style={{marginTop:'20px'}}>
          <Col span={24}>
            <Select 
                placeholder="please select role"
                mode="multiple"
                style={{width: '300px'}}
                onChange={(value) => {
                  this.onValueChangeHandle(value, 'roleList');
                }}>
                {this.state.roleList.map(item => {
                  return (<Option key={item.value} value={item.value}>{item.name}</Option>);
                })}
              </Select>
          </Col>
        </Row>
        <Row style={{marginTop:'20px'}}>  
          <Col span={24}>
              <Select 
                placeholder="please select application"
                style={{width: '300px'}}
                mode="multiple"
                onChange={(value) => {
                  this.onValueChangeHandle(value, 'applicationCodeList');
                }}>
                {this.state.applicationList.map(item => {
                  return (<Option key={item.value} value={item.value}>{item.name}</Option>);
                })}
              </Select>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const {
      isOpened
    } = this.props.applicationManageControlModal;

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

          `}
        </style>
        <Modal key="UserManageModal" size="large" open={isOpened}>
          <Header content={'Add Config'} />
          <Modal.Content>
            {this._createForm()}
          </Modal.Content>
          <Modal.Actions>
              <Button onClick={this.saveData}>
                Submit
              </Button>
              <Button onClick={this.props.closeApplicationManageControlModal}>
                Cancel
              </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default RoleApplicationManageDialog;
