import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const loadSettings = () => {
  return apiClient.post(`${config.api.LOGIN_URL}/userManage/getSettings`);
};

const getUser = () => {
  const url = `${config.api.LOGIN_URL}/userManage/getUser`;
  return apiClient.post(url);
};

const client = {
  loadSettings,
  getUser,
};

export default client;
