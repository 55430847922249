import {
    LOAD_ALL_USER_SUCCESS,
    LOAD_ALL_USER_FAILURE,
    INITIATE_LOADER_STATUS,
    CHANGE_USER_MANAGE_MODAL_INPUT,
    OPEN_USER_MANAGE_MODAL_INPUT,
    CLOSE_USER_MANAGE_MODAL_INPUT,
    SUBMIT_USER_SUCCESS,
    SUBMIT_USER_FAILURE,
    GET_USER_ACCOUNT_CHANGE_LOG_FAILURE,
    GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS,
    GET_USER_ACCOUNT_ACCESS_LOG_FAILURE,
    GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS,
    GET_PM_MANAGE_LIST_FAILURE,
    GET_PM_MANAGE_LIST_SUCCESS
} from './userManageConstants';
import client from './api/client';

export function initiateLoaderStatus() {
    return {
      type: INITIATE_LOADER_STATUS
    };
  }

function loadAllUserSuccess(data) {
    return {
      type: LOAD_ALL_USER_SUCCESS,
      payload: data
    };
  }
  
  function loadAllUserFailure(err) {
    return {
      type: LOAD_ALL_USER_FAILURE,
      payload: err
    };
  }
  
  export function loadAllUser(params) {
    return function(dispatch) {
      dispatch(initiateLoaderStatus())
      client
        .loadAllUser(params)
        .then(resp => dispatch(loadAllUserSuccess(resp)))
        .catch(err => dispatch(loadAllUserFailure(err)));
    };
  }

export function changeUserManageControlModalInput(input) {
    return {
        type: CHANGE_USER_MANAGE_MODAL_INPUT,
        payload: input
      };
}

export function openUserManageControlModal() {
    return {
        type: OPEN_USER_MANAGE_MODAL_INPUT
      };
}

export function closeUserManageControlModal() {
    return {
        type: CLOSE_USER_MANAGE_MODAL_INPUT
      };
}

function submitUserSuccess(data) {
    return {
      type: SUBMIT_USER_SUCCESS,
      payload: data
    };
  }
  
  function submitUserFailure(err) {
    return {
      type: SUBMIT_USER_FAILURE,
      payload: err
    };
  }
  
  export function submitUser(params, queryParams) {
    return function(dispatch) {
      client
        .submitUser(params)
        .then(resp => {
            if(resp && resp.code !== '0'){
              dispatch(submitUserFailure(resp))
            }else{
              dispatch(submitUserSuccess(resp))
              dispatch(closeUserManageControlModal())
              dispatch(loadAllUser(queryParams))
            }
        }).catch((err,resp) => {
          dispatch(submitUserFailure(err))
        });
    };
  }

  export function getUserAccountChangeLog(params) {
    return function(dispatch) {
      client
        .getUserAccountChangeLog(params)
        .then(resp => {
            if(resp && resp.code !== '0'){
              dispatch(getUserAccountChangeLogFailure(resp))
            }else{
              dispatch(getUserAccountChangeLogSuccess(resp))
              dispatch(getUserAccountAccessLog(params))
            }
        }).catch((err,resp) => {
          dispatch(getUserAccountChangeLogFailure(err))
        });
    };
  }

  function getUserAccountChangeLogSuccess(data) {
    return {
      type: GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS,
      payload: data
    };
  }
  
  function getUserAccountChangeLogFailure(err) {
    return {
      type: GET_USER_ACCOUNT_CHANGE_LOG_FAILURE,
      payload: err
    };
  }

  export function getUserAccountAccessLog(params) {
    return function(dispatch) {
      client
        .getUserAccountAccessLog(params)
        .then(resp => {
            if(resp && resp.code !== '0'){
              dispatch(getUserAccountAccessLogFailure(resp))
            }else{
              dispatch(getUserAccountAccessLogSuccess(resp))
            }
        }).catch((err,resp) => {
          dispatch(getUserAccountAccessLogFailure(err))
        });
    };
  }

  function getUserAccountAccessLogSuccess(data) {
    return {
      type: GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS,
      payload: data
    };
  }
  
  function getUserAccountAccessLogFailure(err) {
    return {
      type: GET_USER_ACCOUNT_ACCESS_LOG_FAILURE,
      payload: err
    };
  }

  export function getPmManageList(params) {
    return function(dispatch) {
      client
        .getPmManageList(params)
        .then(resp => {
            if(resp && resp.code !== '0'){
              dispatch(getPmManageListFailure(resp))
            }else{
              dispatch(getPmManageListSuccess(resp))
            }
        }).catch((err,resp) => {
          dispatch(getUserAccountChangeLogFailure(err))
        });
    };
  }
  
  function getPmManageListSuccess(data) {
    return {
      type: GET_PM_MANAGE_LIST_SUCCESS,
      payload: data
    };
  }
  
  function getPmManageListFailure(err) {
    return {
      type: GET_PM_MANAGE_LIST_FAILURE,
      payload: err
    };
  }