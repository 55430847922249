import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { Button } from 'antd'
import client from '../api/client';
import { roleManageListColumn } from './GridColumnMap';
import RolePermissionsDialog from './RolePermissionsDialog';
import { buildPermissionTree } from '../entity/PermissionBuild'

class RoleMagagerListGrid extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          columnDefs: roleManageListColumn,
          defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50,
            width: 130,
            sortable: true,
            filter: true,
            resizable: true
          },
          context: {
            gridComponent: this
          },
          autoGroupColumnDef: [{
            headerName: 'Application',
            field: 'application',
            cellClass: 'keyword',
            width: 230
          }],
          groupDefaultExpanded: 0,
          rowGroupPanelShow: 'always',
          pivotPanelShow: 'always',
          sideBar: {
            toolPanels: ['columns', 'filters']
          },
          data: [],
          rolePermissionModal: {
            isOpened: false,
            permissions: [],
            checkedKeys: []
          },
          selectedData: null
        };
    }

    async componentDidMount(){
      this.getData({});
    }

    getData = async (params) => {
      const data = await client.getRoleList(params);
      if(data && data.data){
        this.setState({
          data: data.data
        })
      }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        this.gridApi.sizeColumnsToFit();
    };

    openRolePermission = () => {
      const { selectedData, rolePermissionModal } =this.state;
      if(!selectedData) return;
      Promise.all([client.getPermissionList({}),
        client.getRolePermissions({roleId: selectedData.roleId})])
      .then(([permissionResult, rolePermissionResult]) => {
        const permissions = permissionResult.data?
          permissionResult.data:[];
        const rolePermissions = rolePermissionResult.data?
          rolePermissionResult.data:[];
        const permissionTree = this._buildTreeData(permissions);
        this.setState({
          rolePermissionModal: {
            ...rolePermissionModal,
            isOpened: true,
            permissions: permissionTree,
            checkedKeys: rolePermissions,
            roleId: selectedData.roleId,
          },
        })
      }).catch(error => {
          console.log(error);
      });
    }

    _onTreeCheck = (keysValue) => {
      const { rolePermissionModal } =this.state;
      this.setState({
        rolePermissionModal: {
          ...rolePermissionModal,
          checkedKeys: keysValue.checked
        },
      })
    }

    _closeModal = () => {
      const { rolePermissionModal } =this.state;
      this.setState({
        rolePermissionModal: {
          ...rolePermissionModal,
          checkedKeys: [],
          permissions: [],
          isOpened: false,
        },
      })
    }

    _buildTreeData = (permissions) => {
      if(_.isEmpty(permissions)) return [];
      const applicationData = permissions.map(r => ({
        parentId: r.parentPermissionId,
        key: r.permissionId,
        title: `${r.permissionName}${r.permissionDescription?'('+r.permissionDescription+')':''}`,
        type: r.type,
        application: r.application
      }));
      return buildPermissionTree(applicationData);
    }

    onSelectionChanged = () => {
      const selectedRows = this.gridApi.getSelectedRows();
      if(selectedRows){
        this.setState({
          selectedData: selectedRows[0]
        })
      }else{
        this.setState({
          selectedData: null
        })
      }
    };

    _saveRolePermission = () => {
      const { 
        checkedKeys, 
        roleId 
      } = this.state.rolePermissionModal;
      const currentCheckedKeys = checkedKeys.filter(r => _.isNumber(r));
      client.saveRolePermission({permissionList: currentCheckedKeys, 
        roleId}).then(resp => {
          this._closeModal();
        }).catch(err => {
          console.log(err);
        })
    }

    render(){
        const { className = 'grid-wrapper' } = this.props;
        const { data } = this.state;
        
        return (
          <div style={{width:'100%',height:'95%', display: 'flex' , flexDirection: 'column'}}>
            <div style={{width:'100%',textAlign: 'right'}}>
              <Button type="primary" onClick={this.openRolePermission}>
                Role Permissions
              </Button>
            </div>
            {!_.isEmpty(data) &&  <div className={`ag-theme-balham-dark ${className}`} style={{height:'90%'}}>
                <AgGridReact
                  // properties
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={data}
                  suppressAggFuncInHeader={true}
                  animateRows={true}
                  enableRangeSelection={true}
                  rowGroupPanelShow={this.state.rowGroupPanelShow}
                  groupDefaultExpanded={this.state.groupDefaultExpanded}
                  pivotPanelShow={this.state.pivotPanelShow}
                  rowSelection="single"
                  context={this.state.context}
                  sideBar={this.state.sideBar}
                  onGridReady={this.onGridReady}
                  onSelectionChanged={this.onSelectionChanged}
                />
            </div>}
            <RolePermissionsDialog 
              {...this.props}
              modalData={this.state.rolePermissionModal}
              closeModal={this._closeModal}
              onTreeCheck={this._onTreeCheck}
              onSubmit={this._saveRolePermission}
            />
        </div>);
    }

}

export default RoleMagagerListGrid;