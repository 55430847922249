import { createReducer } from 'common/utils/reducerUtils';
import { userManageGridColumnMap } from '../userManage/components/GridColumnMap';
import EntityMap from 'entities/EntityMap';
import moment from 'moment';
import {
  LOAD_ALL_USER_SUCCESS,
  LOAD_ALL_USER_FAILURE,
  INITIATE_LOADER_STATUS,
  CHANGE_USER_MANAGE_MODAL_INPUT,
  OPEN_USER_MANAGE_MODAL_INPUT,
  CLOSE_USER_MANAGE_MODAL_INPUT,
  SUBMIT_USER_SUCCESS,
  SUBMIT_USER_FAILURE,
  GET_USER_ACCOUNT_CHANGE_LOG_FAILURE,
  GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS,
  GET_USER_ACCOUNT_ACCESS_LOG_FAILURE,
  GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS,
  GET_PM_MANAGE_LIST_FAILURE,
  GET_PM_MANAGE_LIST_SUCCESS
} from './userManageConstants';

const initialState = {
  isLoaded: false,

  userManageGridColumns: EntityMap.map(userManageGridColumnMap),
  allUsers: [],
  pmManageList: [],
  userManageQueryParams: {
    isActive: ''
  },
  userManageControlModal: {
    detailData: {
      isActive: 1,
      password: 'abc.1234',
      firm: 'PINPOINT',
      joinDate:  moment()
    },
    mode: null,
    isOpened: false,
    submitDataStatus: 'READY',
    submitErrorMsg: null,
    userChangeLogList: []
  }
};

export function initiateLoaderStatus(state) {
  return {
    ...state,
    isLoaded: false
  };
}

function loadAllUserSuccess(state,data) {
  return {
    ...state,
    allUsers: data.data,
    isLoaded: true
  };
}

function loadAllUserFailure(state,err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function changeUserManageControlModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.userManageControlModal,
    [name]: value
  };

  return {
    ...state,
    userManageControlModal: updatedFields
  };
}

export function openUserManageControlModal(state) {
  const updatedFields = {
    ...state.userManageControlModal,
    isOpened: true
  };

  return {
    ...state,
    userManageControlModal: updatedFields
  };
}

export function closeUserManageControlModal(state) {
  const updatedFields = {
    ...state.userManageControlModal,
    isOpened: false,
    detailData: initialState.userManageControlModal.detailData,
    submitDataStatus: 'READY',
    submitErrorMsg: null,
    userChangeLogList: [],
    userAccessLogList: [],
  };

  return {
    ...state,
    userManageControlModal: updatedFields
  };
}

function submitUserSuccess(state,data) {
  const updatedFields = {
    ...state.userManageControlModal,
    submitDataStatus: 'SUCCESS',
    submitErrorMsg: null
  };
  return {
    ...state,
    userManageControlModal: updatedFields
  };
}

function submitUserFailure(state,err) {
  let msg = null;
  if(err){
    msg = err['message'];
  }
  const updatedFields = {
    ...state.userManageControlModal,
    submitDataStatus: 'ERROR',
    submitErrorMsg: msg
  };
  return {
    ...state,
    userManageControlModal: updatedFields
  };
}

function getUserAccountChangeLogSuccess(state, data) {
  const updatedFields = {
    ...state.userManageControlModal,
    userChangeLogList: data.data
  };
  return {
    ...state,
    userManageControlModal: updatedFields 
  };
}

function getUserAccountChangeLogFailure(state, err) {
  return {
    ...state
  };
}

function getUserAccountAccessLogSuccess(state, data) {
  const updatedFields = {
    ...state.userManageControlModal,
    userAccessLogList: data.data
  };
  return {
    ...state,
    userManageControlModal: updatedFields 
  };
}

function getUserAccountAccessLogFailure(state, err) {
  return {
    ...state
  };
}

function getPmManageListSuccess(state,data) {
  return {
    ...state,
    pmManageList: data.data
  };
}

function getPmManageListFailure(state,err) {
  return {
    ...state
  };
}

export default createReducer(initialState, {
  [INITIATE_LOADER_STATUS]: initiateLoaderStatus,

  [LOAD_ALL_USER_SUCCESS]: loadAllUserSuccess,
  [LOAD_ALL_USER_FAILURE]: loadAllUserFailure,
  [CHANGE_USER_MANAGE_MODAL_INPUT]: changeUserManageControlModalInput,
  [OPEN_USER_MANAGE_MODAL_INPUT]: openUserManageControlModal,
  [CLOSE_USER_MANAGE_MODAL_INPUT]: closeUserManageControlModal,
  [SUBMIT_USER_SUCCESS]: submitUserSuccess,
  [SUBMIT_USER_FAILURE]: submitUserFailure,
  [GET_USER_ACCOUNT_CHANGE_LOG_FAILURE]: getUserAccountChangeLogFailure,
  [GET_USER_ACCOUNT_CHANGE_LOG_SUCCESS]: getUserAccountChangeLogSuccess,
  [GET_PM_MANAGE_LIST_FAILURE]: getPmManageListFailure,
  [GET_PM_MANAGE_LIST_SUCCESS]: getPmManageListSuccess,
  [GET_USER_ACCOUNT_ACCESS_LOG_FAILURE]: getUserAccountAccessLogFailure,
  [GET_USER_ACCOUNT_ACCESS_LOG_SUCCESS]: getUserAccountAccessLogSuccess,
});
