import _ from 'lodash';
import  arrayToTree  from "array-to-tree";

export const buildPermissionTree = (data) => {
    if(_.isEmpty(data)) return [];
    // group by application
    const applicationMap = _.groupBy(data, 'application');
    return Object.keys(applicationMap).map(r => {
        return {
            key: `APP_${r}`,
            title: r,
            children: buildTreeData(applicationMap[r], 'key', 'parentId')
        }
    });
}

const buildTreeData = (data, keyValue ,parentFiled) => {
    return arrayToTree(data, 
        {
            customID: keyValue, 
            parentProperty: parentFiled, 
            childrenProperty: "children"
        });
}