import { createReducer } from 'common/utils/reducerUtils';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_RESET,
  LOGOUT_SUCCESS,
  CHG_PWD_REQUEST,
  CHG_PWD_SUCCESS,
  CHG_PWD_FAILURE,
  CHG_PWD_RESET,
  LS_KEY_USER_TOKEN
} from './authConstants';
import { localStorageClient } from 'common/api/localStorageClient';

const accessToken = localStorageClient.getFromCookie(LS_KEY_USER_TOKEN);
const initialState = {
  isLoggedIn: !!accessToken,
  accessToken,
  loginStatus: 'READY',
  chgPwdStatus: 'READY',
  errorMsg: null
};

export function loginRequest(state, payload) {
  return {
    ...state,
    loginStatus: 'LOGINING',
    errorMsg: null
  };
}

export function loginSuccess(state, payload) {
  const { accessToken } = payload;
  return {
    ...state,
    loginStatus: 'SUCCESS',
    isLoggedIn: true,
    accessToken
  };
}

export function loginFailure(state, payload) {
  return {
    ...state,
    loginStatus: 'ERROR',
    isLoggedIn: false,
    accessToken: null,
    errorMsg: payload.message
  };
}

export function resetLoginForm(state, payload) {
  return {
    ...state,
    loginStatus: 'READY'
  };
}

export function logoutSuccess(state) {
  return {
    ...state,
    isLoggedIn: false,
    accessToken: null
  };
}

export function chgPwdRequest(state, payload) {
  return {
    ...state,
    chgPwdStatus: 'LOGINING'
  };
}

export function chgPwdSuccess(state, payload) {
  return {
    ...state,
    chgPwdStatus: 'SUCCESS'
  };
}

export function chgPwdFailure(state, payload) {
  return {
    ...state,
    chgPwdStatus: 'ERROR'
  };
}

export function resetChgPwdForm(state, payload) {
  return {
    ...state,
    chgPwdStatus: 'READY'
  };
}

export default createReducer(initialState, {
  [LOGIN_REQUEST]: loginRequest,
  [LOGIN_SUCCESS]: loginSuccess,
  [LOGIN_FAILURE]: loginFailure,
  [LOGIN_RESET]: resetLoginForm,
  [CHG_PWD_REQUEST]: chgPwdRequest,
  [CHG_PWD_SUCCESS]: chgPwdSuccess,
  [CHG_PWD_FAILURE]: chgPwdFailure,
  [CHG_PWD_RESET]: resetChgPwdForm,
  [LOGOUT_SUCCESS]: logoutSuccess
});
