import cookie from '../utils/CookieUtil'

const useLocalStorage = typeof localStorage !== 'undefined';

const get = key => {
  if (useLocalStorage) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (err) {
      return item;
    }
  }
};

const save = (key, value) => {
  if (useLocalStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const remove = key => {
  if (useLocalStorage) {
    localStorage.removeItem(key);
  }
};

const getFromCookie = key => {
  return cookie.getCookie(key);
};

const removeFromCookie = key => {
  return cookie.delCookie(key);
};

export const localStorageClient = {
  get,
  save,
  remove,
  getFromCookie,
  removeFromCookie
};
