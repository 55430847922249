import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app/layout/App';
import configureStore from './app/store/configureStore';
import { logout } from 'features/auth/authActions';
import apiClient from './common/api/apiClient';
import { loadSettings, loadUser } from 'features/settings/settingActions';

import 'semantic-ui-css-offline-fonts/semantic.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'handsontable/dist/handsontable.full.css';

import { LicenseManager } from 'ag-grid-enterprise/main';
LicenseManager.setLicenseKey(
  'Shanghai_Pinpoint_Investment_Management_Limited_IMS_1Devs8_August_2019__MTU2NTIxODgwMDAwMA==95c1392e256d2e14a9760c2e378224c3'
);

const store = configureStore();
// Handle auth token in apiClient.
apiClient.register({
  request: (url, config) => {
    return [
      url,
      {
        ...config,
        headers: {
          ...(config.headers || {})
        }
      }
    ];
  },
  response: response => {
    if (response.status === 401) {
      store.dispatch(logout());
    }

    return response;
  }
});

// Invoke several initial data loading operations.
const {
  auth: { isLoggedIn }
} = store.getState();
if (isLoggedIn) {
  store.dispatch(loadSettings());
  store.dispatch(loadUser());
}

// Render the app.
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
