import React, { Component } from 'react';
import { Tabs } from 'antd';
import BrokerScoreGrid from './brokerScore/BrokerScoreGrid'
import './ScoreDashboard.css';

const TabPane = Tabs.TabPane;
class ScoreDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
  }

  tabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  render() {
    return (
      <div className="scoreDashboardWrapper">
          <Tabs
          tabPosition={'top'}
          onChange={this.tabChange}
          activeKey={this.state.activeTab}
        >
          <TabPane tab="Broker Score" key="1">
            <BrokerScoreGrid {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ScoreDashboard;
