import { combineReducers } from 'redux';
import authReducer from 'features/auth/authReducer';
import settingReducer from 'features/settings/settingReducer';
import userManageReducer from 'features/userManage/userManageReducer'
import layoutReducer from 'features/layout/layoutReducer'
import applicationManageReducer from 'features/applicationManage/applicationManageReducer'
import scoreReducer from 'features/score/scoreReducer'
import sourceManageReducer from 'features/sourceManage/sourceManageReducer'
const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingReducer,
  userManage: userManageReducer,
  layout: layoutReducer,
  applicationManage: applicationManageReducer,
  scoreSystem: scoreReducer,
  sourceManage: sourceManageReducer,
});

export default rootReducer;
