import { connect } from 'react-redux';

import ApplicationManageContainer from '../components/ApplicationManageDashboard';

import {
  getAllApplication,
  openApplicationManageControlModal,
  closeApplicationManageControlModal,
  addRoleApplicationConfig,
  getUserAccountList
} from '../applicationManageActions';

const mapStateToProps = state => {
  const user = state.settings.user;

  return {
    user,
    isLoaded: state.applicationManage.isLoaded,
    allApplicationList: state.applicationManage.allApplicationList,
    applicationManageGridColumns: state.applicationManage.applicationManageGridColumns,
    applicationManageQueryParams: state.applicationManage.applicationManageQueryParams,
    applicationManageControlModal: state.applicationManage.applicationManageControlModal,
    innerUserAccountList: state.applicationManage.innerUserAccountList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllApplication: params => dispatch(getAllApplication(params)),
    openApplicationManageControlModal: input => dispatch(openApplicationManageControlModal(input)),
    closeApplicationManageControlModal: () => dispatch(closeApplicationManageControlModal()),
    addRoleApplicationConfig: params=> dispatch(addRoleApplicationConfig(params)),
    getUserAccountList: () => dispatch(getUserAccountList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationManageContainer);
