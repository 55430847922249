import {
    INITIATE_LOADER_STATUS,
    INITIATE_BROKER_LOADER_STATUS,
    LOAD_EXTERNAL_SOURCE_SUCCESS,
    LOAD_EXTERNAL_SOURCE_FAILURE,
    LOAD_BROKER_SOURCE_SUCCESS,
    LOAD_BROKER_SOURCE_FAILURE,

    CHANGE_EXTERNAL_SOURCE_MODAL_INPUT,
    OPEN_EXTERNAL_SOURCE_MODAL_INPUT,
    CLOSE_EXTERNAL_SOURCE_MODAL_INPUT,
    SUBMIT_EXTERNAL_SOURCE_SUCCESS,
    SUBMIT_EXTERNAL_SOURCE_FAILURE,
} from './sourceManageConstants';
import client from './api/client';



  export function initiateLoaderStatus() {
    return {
      type: INITIATE_LOADER_STATUS
    };
  }

  export function initiateBrokerLoaderStatus() {
    return {
      type: INITIATE_BROKER_LOADER_STATUS
    };
  }
  function loadExternalSourceSuccess(data) {
    return {
      type: LOAD_EXTERNAL_SOURCE_SUCCESS,
      payload: data
    };
  }
  
  function loadExternalSourceFailure(err) {
    return {
      type: LOAD_EXTERNAL_SOURCE_FAILURE,
      payload: err
    };
  }
  
  export function loadExternalSource(params) {
    return function(dispatch) {
      dispatch(initiateLoaderStatus())
      let str = '?';
      if (typeof params.sourceName !== 'undefined'){
        str = str + 'sourceName='+params.sourceName +'&'
      }
      if (typeof params.sourceType !== 'undefined'){
        str = str + 'sourceType='+params.sourceType+'&'
      }
      if (typeof params.userName !== 'undefined'){
        str = str + 'userName='+params.userName+'&'
      }
      if (typeof params.externalType !== 'undefined'){
        str = str + 'externalType='+params.externalType+'&'
      }
      if (typeof params.isAdmin !== 'undefined'){
        str = str + 'isAdmin='+params.isAdmin+'&'
      }
      client
        .loadExternalSource(str)
        .then(resp => dispatch(loadExternalSourceSuccess(resp)))
        .catch(err => dispatch(loadExternalSourceFailure(err)));
    };
  }
  export function loadBrokerSource(params) {
    return function(dispatch) {
      dispatch(initiateBrokerLoaderStatus())
      let str = '?';
      if (typeof params.sourceName !== 'undefined'){
        str = str + 'sourceName='+params.sourceName +'&'
      }
      if (typeof params.sourceType !== 'undefined'){
        str = str + 'sourceType='+params.sourceType+'&'
      }
      if (typeof params.userName !== 'undefined'){
        str = str + 'userName='+params.userName+'&'
      }
      if (typeof params.externalType !== 'undefined'){
        str = str + 'externalType='+params.externalType+'&'
      }
      if (typeof params.isAdmin !== 'undefined'){
        str = str + 'isAdmin='+params.isAdmin+'&'
      }
      client
        .loadExternalSource(str)
        .then(resp => dispatch(loadBrokerSourceSuccess(resp)))
        .catch(err => dispatch(loadBrokerSourceFailure(err)));
    };
  }
  

  function loadBrokerSourceSuccess(data) {
    return {
      type: LOAD_BROKER_SOURCE_SUCCESS,
      payload: data
    };
  }
  
  function loadBrokerSourceFailure(err) {
    return {
      type: LOAD_BROKER_SOURCE_FAILURE,
      payload: err
    };
  }

  export function changeExternalSourceControlModalInput(input) {
    return {
        type: CHANGE_EXTERNAL_SOURCE_MODAL_INPUT,
        payload: input
      };
}

export function openExternalSourceControlModal() {
    return {
        type: OPEN_EXTERNAL_SOURCE_MODAL_INPUT
      };
}

export function closeExternalSourceControlModal() {
    return {
        type: CLOSE_EXTERNAL_SOURCE_MODAL_INPUT
      };
}

function submitExternalSourceSuccess(data) {
    return {
      type: SUBMIT_EXTERNAL_SOURCE_SUCCESS,
      payload: data
    };
  }
  
  function submitExternalSourceFailure(err) {
    return {
      type: SUBMIT_EXTERNAL_SOURCE_FAILURE,
      payload: err
    };
  }
  
  export function submitExternalSource(params, queryParams) {
    return function(dispatch) {
      let str = '?';
      if (typeof params.sourceName !== 'undefined'){
        str = str + 'sourceName='+params.sourceName +'&'
      }
      if (typeof params.sourceType !== 'undefined'){
        str = str + 'sourceType='+params.sourceType+'&'
      }
      if (typeof params.ownerId !== 'undefined'){
        str = str + 'ownerId='+params.ownerId+'&'
      }
      if (typeof params.ownerType !== 'undefined'){
        str = str + 'ownerType='+params.ownerType+'&'
      }
      if (typeof params.purchaseAmount !== 'undefined'){
        str = str + 'purchaseAmount='+params.purchaseAmount+'&'
      }
      if (typeof params.purchaseDesc !== 'undefined'){
        str = str + 'purchaseDesc='+params.purchaseDesc+'&'
      }
      if (typeof params.purchaseDate !== 'undefined'){
        str = str + 'purchaseDate='+params.purchaseDate+'&'
      }
      if (typeof params.expireDate !== 'undefined'){
        str = str + 'expireDate='+params.expireDate+'&'
      }
      if (typeof params.sourceDesc !== 'undefined'){
        str = str + 'sourceDesc='+params.sourceDesc+'&'
      }
      if (typeof params.op !== 'undefined'){
        str = str + 'mode='+params.op+'&'
      }
      if (typeof params.sourceId !== 'undefined'){
        str = str + 'sourceId='+params.sourceId+'&'
      }

      client
        .submitExternalSource(str)
        .then(resp => {
            if(resp && resp.respCode !== 'S0001'){
              dispatch(submitExternalSourceFailure(resp))
            }else{
              dispatch(submitExternalSourceSuccess(resp))
              dispatch(closeExternalSourceControlModal())
              dispatch(loadExternalSource(queryParams))
            }
        }).catch((err,resp) => {
          dispatch(submitExternalSourceFailure(err))
        });
    };
  }