import { createReducer } from 'common/utils/reducerUtils';
import {
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  OPEN_USER_SETTINGS_DIALOG,
  RESET_USER_SETTINGS_DIALOG,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILURE,
} from './settingConstants';
import { LOGOUT_SUCCESS } from '../auth/authConstants';

const initialState = {
  funds: [],
  isLoaded: false,
  fieldsByCategory: {},
  tradeLabel: 'Idea',
  routes: [],
  user:{},
  helpModal: {
    isOpened: false
  },
  userSettingModal: {
    isOpened: false,
    user: null
  }
};


export function loadSettingsSuccess(state, payload) {
  return {
    ...state,
    isLoaded: true,
    user: payload
  };
}

export function loadSettingsFailure(state, payload) {
  return {
    ...initialState,
    isLoaded: true
  };
}

export function logoutSuccess() {
  return {
    ...initialState
  };
}

export function requestLoadSettings(state) {
  return initialState;
}

export function openUserSettingDialog(state) {
  return {
    ...state,
    userSettingModal: {
      ...state.userSettingModal,
      isOpened: true
    }
  };
}

export function resetUserSettingDialog(state) {
  return {
    ...state,
    userSettingModal: {
      ...state.userSettingModal,
      isOpened: false
    }
  };
}

export function loadUserSuccess(state, payload) {
  return {
    ...state,
    userSettingModal: {
      ...state.userSettingModal,
      user: payload
    }
  };
}

export function loadUserFailure(state, payload) {
  return {
    ...initialState,
  };
}

export default createReducer(initialState, {
  [LOAD_SETTINGS_SUCCESS]: loadSettingsSuccess,
  [LOAD_SETTINGS_FAILURE]: loadSettingsFailure,
  [LOGOUT_SUCCESS]: logoutSuccess,
  [OPEN_USER_SETTINGS_DIALOG]: openUserSettingDialog,
  [RESET_USER_SETTINGS_DIALOG]: resetUserSettingDialog,
  [LOAD_USER_SUCCESS]: loadUserSuccess,
  [LOAD_USER_FAILURE]: loadUserFailure,
});
