import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_RESET,
  LOGOUT_SUCCESS,
  CHG_PWD_REQUEST,
  CHG_PWD_SUCCESS,
  CHG_PWD_FAILURE,
  CHG_PWD_RESET,
  CHG_LOGIN_STATUS
} from './authConstants';
import client from './api/client';
import {localStorageClient} from "common/api/localStorageClient"
import { loadSettings,loadUser } from '../settings/settingActions';

function loginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload
  };
}

function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    payload: err
  };
}

export function login(user) {
  return function(dispatch) {
    dispatch(loginRequest());
    client
      .login(user)
      .then(payload => {
        dispatch(loginSuccess(payload));
        dispatch(loadSettings());
        dispatch(loadUser());
        const lastPage = localStorageClient.get("lastPage");
        if(lastPage && !lastPage.endsWith("/login") && !lastPage.endsWith("/machineReg")){
          dispatch(initLastPage());
        }
      })
      .catch(err => dispatch(loginFailure(err)));
  };
}


function initLastPage() {
  const lastPage = localStorageClient.get("lastPage");
  window.location.href = lastPage;
}

export function resetLoginForm() {
  return {
    type: LOGIN_RESET
  };
}

function chgPwdRequest() {
  return {
    type: CHG_PWD_REQUEST
  };
}

function chgPwdSuccess() {
  return {
    type: CHG_PWD_SUCCESS
  };
}

function chgPwdFailure(err) {
  return {
    type: CHG_PWD_FAILURE,
    payload: err
  };
}

export function chgPwd(pwdObj) {
  return function(dispatch) {
    dispatch(chgPwdRequest());
    client
      .chgPwd(pwdObj)
      .then(_ => {
        dispatch(chgPwdSuccess());
      })
      .catch(err => dispatch(chgPwdFailure(err)));
  };
}

export function resetChgPwdForm() {
  return {
    type: CHG_PWD_RESET
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logout() {
  return function(dispatch) {
    client
    .logout()
    .then(resp => dispatch(logoutSuccess()))
    .catch(err => {
      console.log(err)
    });
  };
}