import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { Switch, Checkbox } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { pmManageListColumn } from './GridColumnMap';
import config from 'common/config';

class PmMagagerListGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
          columnDefs: pmManageListColumn,
          defaultColDef: {
            enableCellChangeFlash: true,
            minWidth: 50,
            width: 130,
            sortable: true,
            filter: true,
            resizable: true
          },
          context: {
            gridComponent: this
          },
          autoGroupColumnDef: [{
            headerName: 'Manager',
            field: 'manager',
            cellClass: 'keyword',
            width: 230
          },{
            headerName: 'Fund',
            field: 'fundCode',
            cellClass: 'keyword',
            width: 230
          }],
          groupDefaultExpanded: 0,
          rowGroupPanelShow: 'always',
          pivotPanelShow: 'always',
          sideBar: {
            toolPanels: ['columns', 'filters']
          },
          queryBookMode: true ,
          bookShow: true
        };
    }

    componentDidMount(){
        this.props.getPmManageList({mode: this.state.queryBookMode, system: config.system});
    }

    getPmManageList = () => {
        const { queryBookMode } = this.state;
        const mode = !queryBookMode;
        this.setState({
            queryBookMode: mode
        })
        this.props.getPmManageList({mode: mode, system: config.system});
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.gridApi.sizeColumnsToFit();
    };

    bookShowChange = () =>{
      const { bookShow } = this.state;
      this.setState({
        bookShow: !bookShow
      });
    }

    render(){
        const { pmManageList,className = 'grid-wrapper' } = this.props;
        const { queryBookMode,columnDefs,autoGroupColumnDef,bookShow } = this.state;
        let columnsDefList = columnDefs;
        let groupColumnDef = autoGroupColumnDef;
        let sortedRows = _.orderBy(pmManageList, ['manager'], ['asc']);
        if(!bookShow && queryBookMode){
          sortedRows = sortedRows.filter(r => !r.bookCode);
        }
        if(queryBookMode){
            columnsDefList = columnDefs.filter(r=> r.field !== 'fundCode');
            groupColumnDef = autoGroupColumnDef.filter(r=> r.field !== 'fundCode');
        }else{
          columnsDefList = columnDefs.filter(r=> r.field !== 'startDate' || r.field !== 'endDate');
        }
        return (
            <div style={{height:'100%'}}>
                <div style={{
                    textAlign: 'right',
                    marginBottom: '2px',
                }}>
                  {queryBookMode?(
                    <Checkbox
                      checked={this.state.bookShow}
                      onChange={this.bookShowChange}
                      style={{color: 'white'}}
                    >
                      Contains PM
                    </Checkbox>
                  ):("")}
                    <Switch
                        checked={queryBookMode}
                        checkedChildren="Book-View"
                        unCheckedChildren="Fund-View"
                        defaultChecked
                        onChange={this.getPmManageList}
                    />
                </div>

                <div className={`ag-theme-balham-dark ${className}`} style={{height:'90%'}}>
                    <AgGridReact
                      // properties
                      columnDefs={columnsDefList}
                      defaultColDef={this.state.defaultColDef}
                      rowData={sortedRows}
                      suppressAggFuncInHeader={true}
                      animateRows={true}
                      autoGroupColumnDef={groupColumnDef}
                      enableRangeSelection={true}
                      rowGroupPanelShow={this.state.rowGroupPanelShow}
                      groupDefaultExpanded={this.state.groupDefaultExpanded}
                      pivotPanelShow={this.state.pivotPanelShow}
                      rowSelection="single"
                      context={this.state.context}
                      sideBar={this.state.sideBar}
                      onGridReady={this.onGridReady}
                    />
                </div>
        </div>);
    }

}

export default PmMagagerListGrid;
