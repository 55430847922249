import React, { Component } from 'react';
import { List,Divider } from 'antd';

class InnerNewsPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data : [
                // {'title': '2020-12-24全员聚餐, 吃开封菜,2020-12-24全员聚餐, 吃开封菜', 'url': '#', 'publishDate': '2020-12-23 17:00:53'},
                // {'title': '2020-12-25全员聚餐, 吃开封菜,2020-12-24全员聚餐, 吃开封菜', 'url': '#', 'publishDate': '2020-12-23 17:00:53'},
                // {'title': '2020-12-26全员聚餐, 吃开封菜,2020-12-24全员聚餐, 吃开封菜', 'url': '#', 'publishDate': '2020-12-23 17:00:53'},
                // {'title': '2020-12-27全员聚餐, 吃开封菜,2020-12-24全员聚餐, 吃开封菜', 'url': '#', 'publishDate': '2020-12-23 17:00:53'}
            ]
        }
    }

    render() {
        return (
            <div>
                <Divider orientation="left" style={{color:"white",fontSize: '20px'}}>Others</Divider>
                <List
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item>
                            <div style={{marginLeft: '10px'}}>
                                <a href={item.url} style={{color:"white"}}>{item.title}</a> 
                                <span style={{marginLeft:'10px',color:"white"}}>UpdateTime: {item.publishDate}</span>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}

export default InnerNewsPanel;