import {
  INITIATE_LOADER_STATUS,

  OPEN_BROKER_SCORES_DETAIL_DIALOG,
  RESET_BROKER_SCORES_DETAIL_DIALOG,
  LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
  LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
  LOAD_BROKER_SCORE_LIST_SUCCESS,
  LOAD_BROKER_SCORE_LIST_FAILURE,
  SAVE_BROKER_SCORE_SUCCESS,
  SAVE_BROKER_SCORE_FAILURE,
  REMOVE_BROKER_SCORE_SUCCESS,
  REMOVE_BROKER_SCORE_FAILURE,
  LOAD_CORRESPONDENCE_LIST_SUCCESS,
  LOAD_CORRESPONDENCE_LIST_FAILURE,
  INITIATE_WEIGHT_MAP_LOADER_STATUS,
  CHANGE_BROKER_QUERY_PARAMS,
  CHANGE_BROKER_SCORE_MODAL_INPUT,
} from './scoreConstants';
import client from './api/client';

export function initiateLoaderStatus() {
    return {
      type: INITIATE_LOADER_STATUS
    };
  }

 //broker
/**
 * Broker
 */
export function openBrokerScoresDetailDialog(votingMonth) {
  return {
    type: OPEN_BROKER_SCORES_DETAIL_DIALOG,
    payload: votingMonth
  };
}

// reset research control
export function resetBrokerDetailControlModal() {
  return {
    type: RESET_BROKER_SCORES_DETAIL_DIALOG
  };
}

function loadBrokerScoreListByScoreSuccess(result) {
  return {
    type: LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
    payload: result
  };
}

function loadBrokerScoreListByScoreFailure(err) {
  return {
    type: LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
    payload: err
  };
}

export function loadBrokerScoreListByScore(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBrokerScoreListByScore(queryParams)
      .then(resp => dispatch(loadBrokerScoreListByScoreSuccess(resp)))
      .catch(err => dispatch(loadBrokerScoreListByScoreFailure(err)));
  };
}

function loadBrokerScoreListSuccess(result) {
  return {
    type: LOAD_BROKER_SCORE_LIST_SUCCESS,
    payload: result
  };
}

function loadBrokerScoreListFailure(err) {
  return {
    type: LOAD_BROKER_SCORE_LIST_FAILURE,
    payload: err
  };
}

export function loadBrokerScoreList(queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .loadBrokerScoreList(queryParams)
      .then(resp => dispatch(loadBrokerScoreListSuccess(resp)))
      .catch(err => dispatch(loadBrokerScoreListFailure(err)));
  };
}

function saveBrokerScoreSuccess(result) {
  return {
    type: SAVE_BROKER_SCORE_SUCCESS,
    payload: result
  };
}

function saveBrokerScoreFailure(err) {
  return {
    type: SAVE_BROKER_SCORE_FAILURE,
    payload: err
  };
}

export function saveBrokerScore(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .saveBrokerScore(params)
      .then(resp => {
        dispatch(saveBrokerScoreSuccess(resp));
        dispatch(loadBrokerScoreList(queryParams));
      })
      .catch(err => dispatch(saveBrokerScoreFailure(err)));
  };
}

function removeBrokerScoreSuccess(result) {
  return {
    type: REMOVE_BROKER_SCORE_SUCCESS,
    payload: result
  };
}

function removeBrokerScoreFailure(err) {
  return {
    type: REMOVE_BROKER_SCORE_FAILURE,
    payload: err
  };
}

export function removeBrokerScore(params, queryParams) {
  return function(dispatch) {
    dispatch(initiateLoaderStatus());
    client
      .removeBrokerScore(params)
      .then(resp => {
        dispatch(removeBrokerScoreSuccess(resp));
        dispatch(loadBrokerScoreList(queryParams));
      })
      .catch(err => dispatch(removeBrokerScoreFailure(err)));
  };
}

function loadCorrespondenceListSuccess(result) {
  return {
    type: LOAD_CORRESPONDENCE_LIST_SUCCESS,
    payload: result
  };
}

function loadCorrespondenceListFailure(err) {
  return {
    type: LOAD_CORRESPONDENCE_LIST_FAILURE,
    payload: err
  };
}

export function loadCorrespondenceList(params) {
  return function(dispatch) {
    dispatch(initiateWeightMapLoaded());
    client
      .loadCorrespondenceList(params)
      .then(resp => dispatch(loadCorrespondenceListSuccess(resp)))
      .catch(err => dispatch(loadCorrespondenceListFailure(err)));
  };
}

export function initiateWeightMapLoaded() {
  return {
    type: INITIATE_WEIGHT_MAP_LOADER_STATUS
  };
}

export function changeBrokerQureyParams(input) {
  return {
    type: CHANGE_BROKER_QUERY_PARAMS,
    payload: input
  };
}

export function changeBrokerScoreControlModalInput(input) {
  return {
    type: CHANGE_BROKER_SCORE_MODAL_INPUT,
    payload: input
  };
}