import React, { Component } from 'react';
import './HomePageDashboard.css';
import SplitPane from 'react-split-pane';
import TodoListPanel from './TodoListPanel';
import SystemHrefPanel from './SystemHrefPanel';
import InnerNewsPanel from './InnerNewsPanel'

class HomePageDashboard extends Component {

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <SplitPane
            split="vertical"
            defaultSize="25%"
            style={{ position: 'relative'}}
        >
          <TodoListPanel
              {...this.props}
          />
          <SplitPane
                split="horizontal"
                defaultSize="65%"
                style={{ position: 'relative'}}
            >
              <SystemHrefPanel
                {...this.props}
              />
              <InnerNewsPanel 
                {...this.props}/>
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}

export default HomePageDashboard;
