import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const login = ({ name, pwd, authCode,expirePeriod }) => {
  return apiClient
    .post(config.api.LOGIN_URL + '/auth/loginMachine', {
      userName: name,
      pwd: pwd,
      authCode: authCode,
      expirePeriod: expirePeriod
    })
    .then(resp => {
      if (resp && resp.code === '0') {
        return resp.data;
      } else throw new Error(resp.msg);
    });
};

const chgPwd = ({ name, oldPwd, newPwd }) => {
  return apiClient.post(config.api.AUTH_URL + '/auth/chgPwd', {
    userName: name,
    oldPwd,
    newPwd
  });
};

const logout = () => {
  return apiClient
    .post(config.api.LOGIN_URL + '/auth/loginOut')
    .then(resp => {
    });
};

const genVerifyBarcode = () => {
  return apiClient.get(config.api.AUTH_URL + '/userManage/genVerifyBarcode')
};

const checkUserStatus = (accessToken) => {
  return apiClient.get(config.api.LOGIN_URL + '/auth/checkUserStatus')
}

const getAuthCode = (params) => {
  return apiClient.post(config.api.AUTH_URL + '/verify/getAuthCode',params)
}

const getLoginAuthCode = (params) => {
  return apiClient.post(config.api.LOGIN_URL + '/verify/getLoginAuthCode',params)
}

const submitAuth = (params) => {
  return apiClient.post(config.api.LOGIN_URL + '/verify/submitAuth',params)
}

const client = {
  login,
  chgPwd,
  logout,
  genVerifyBarcode,
  checkUserStatus,
  getAuthCode,
  getLoginAuthCode,
  submitAuth
};

export default client;
