import React from 'react';
import { Dropdown, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import VersionTag from './VersionTag';
import config from 'common/config';

const TopNavBar = ({
  isLoggedIn,
  toggleSideBar,
  openUserSettingDialog,
  user = {}
}) => {
  return (
    <Menu inverted attached="top" className="horizontal-nav-bar">
      <Menu.Item header>
        <Icon name="bars" onClick={toggleSideBar} />
        {config.title}
      </Menu.Item>
      <Menu.Menu position="right">
        <VersionTag />
        <Dropdown item icon="setting" simple>
          <Dropdown.Menu>
            <Dropdown.Header>
              {isLoggedIn ? user.englishName : 'Anonymous'}
            </Dropdown.Header>
            {isLoggedIn && (
               <Dropdown.Item>
                 <Link to="/" style={{ color: 'black' }}>
                   Home Page
                 </Link>
                </Dropdown.Item>
            )}
            <Dropdown.Divider />
            {isLoggedIn && (
              <>
                <Dropdown.Item>
                  <Link to="/chgpwd" style={{ color: 'black' }}>
                    Change password
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={openUserSettingDialog}>User Setting</Dropdown.Item>
              </>
            )}
            <Dropdown.Divider />

            <Dropdown.Item>
              {isLoggedIn ? (
                <Link to="/logout" style={{ color: 'black' }}>
                  Logout
                </Link>
              ) : (
                <Link to="/login" style={{ color: 'black' }}>
                  Login
                </Link>
              )}
            </Dropdown.Item>
            
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default TopNavBar;
