/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Divider, Switch } from 'antd';
import _ from 'lodash';
import apiClient from '../../../common/api/apiClient';
import client from '../api/client';
import { AgGridReact } from 'ag-grid-react';
import {localStorageClient} from "common/api/localStorageClient"
import {LS_KEY_USER_TOKEN} from "features/auth/authConstants"

class TodoListPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [
            ],
            selectedData: null,
            columnDefs: [
                {
                    field: 'applicationCode',
                    headerName: 'Application',
                    cellClass: 'non-number',
                    enableRowGroup: true,
                    autoHeight: true,
                    rowGroup: true,
                    hide: true
                },
                {
                    field: 'title',
                    headerName: 'Title',
                    cellClass: 'non-number'
                }
            ],
            defaultColDef: {
              enableCellChangeFlash: true,
              minWidth: 50,
              width: 130,
              sortable: true,
              filter: true,
              resizable: true
            },
            context: {
              gridComponent: this
            },
            autoGroupColumnDef: {
              headerName: 'Application',
              field: 'applicationCode',
              cellClass: 'keyword',
              width: 60
            },
            groupDefaultExpanded: -1,
            yztime: 59,
            defaultGetToDoListSystem:['cmsReport','OA'],
            isRealtimeMode: true,
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        this.gridApi.sizeColumnsToFit();
    };

    //页面挂载后开始轮询,每隔10秒
    componentDidMount(){
        this.refreshTodoList()
        this.timer=setInterval(()=>{
            const accessToken = localStorageClient.getFromCookie(LS_KEY_USER_TOKEN);
            if(accessToken){
                this.refreshTodoList();
            }
	    },10000)
    }

    componentWillUnmount(){
        if(this.timer){
            clearInterval(this.timer);
        }
    }

    refreshTodoList = (isForce) =>{
        if(!this.state.isRealtimeMode && !isForce) return;
        let reusltData = [];
        const todoListApplication =this.loadTodoList()
        if(!_.isEmpty(todoListApplication)){
            todoListApplication.forEach(item=>{
                Promise.all([apiClient.get(item.todoListUrl)])
                .then(([data]) => {
                    const result = data.data;
                    if(!result){
                        return;
                    }
                    // if(item.applicationCode === 'CMS'){
                    //     const data = result.map(element=>{
                    //         return {
                    //             applicationCode: item.applicationCode,
                    //             title: element.taskTitle,
                    //             url: item.url + element.redirectURI
                    //         }
                    //     });
                    //     reusltData = reusltData.concat(data);
                    // }
                    if(item.applicationCode === 'cmsReport'){
                        const data = result.filter(r=>r.approvalStatus==='1' && r.actualQty !== 0 && _.isEmpty(r.attachment))
                        .map(element=>{
                            return {
                                applicationCode: item.applicationName,
                                title: element.secName,
                                url: item.url + 'home/personal-trade-request'
                            }
                        })
                        reusltData = reusltData.concat(data);
                    }
                    if(item.applicationCode === 'PETS' || item.applicationCode === 'CMS'){
                        const data = result
                        .map(element=>{
                            const url = element.redirectUri.indexOf("http://")===0 || element.redirectUri.indexOf("https://")===0?element.redirectUri:item.url + element.redirectUri;
                            return {
                                applicationCode: element.fromApplication,
                                title: element.taskTitle,
                                url: url,
                                updateBy: item.applicationCode === 'PETS'?'-999':null,
                                id: element.id
                            }
                        })
                        reusltData = reusltData.concat(data);
                    }
                    if(item.applicationCode === 'OA'){
                        const data = result.map(element=>{
                            return {
                                applicationCode: item.applicationName,
                                title: element.name,
                                url: item.url + '/humantask/workspace-personalTasks.do'
                            }
                        })
                        reusltData = reusltData.concat(data);
                    }
                    reusltData = _.orderBy(reusltData, ['applicationCode'], ['asc']);
                    this.setState({
                        data: reusltData
                    });
                })
                .catch(ex => {
                    console.log(ex);
                });
            })
           
        }
    }

    loadTodoList = () => {
        const data = this.props.user;
        if(data && data.applicationList){
            let result = data.applicationList;
            const todoListApplication = [];
            result.forEach(element => {
                if((element.needGetTodoList === 1 || this.state.defaultGetToDoListSystem.includes(element.code)) 
                    && element.todoListUrl){
                    todoListApplication.push({
                        'applicationCode': element.code,
                        'applicationName': element.name,
                        'todoListUrl': element.todoListUrl,
                        'url': element.url
                    })
                }
            });
            return todoListApplication;
        }
        return null;
    }

    onRowDoubleClick = evt =>{
        if (!evt.data) return;
        const data = evt.data;
        window.open(data.url);
    }

    getContextMenuItems = (params) => {
        const finishTask = this.finishTask;
        const resultItems = [];
        if (params.node == null) return resultItems;
        const row = params.node.data;
        if(row.updateBy === '-999'){
            resultItems.push({
                name: 'Finish Task',
                action: function () {
                    finishTask()
                },
            });
        }
        return resultItems;
    }

    finishTask = () =>{
        const {
            selectedData
        } = this.state;
        if(selectedData){
            client.finishTaskList(selectedData).then(resp=>{
                this.refreshTodoList(true);
            }).catch(ex => {
               console.log(ex);
            });
        }
    }

    onRealtimeModeChange = () => {
        const { isRealtimeMode } = this.state;
        this.setState({
            isRealtimeMode: !isRealtimeMode
        })
    }

    onSelectionChanged = () => {
        const selectedRows = this.gridApi.getSelectedRows();
    
        this.setState({
          selectedData: selectedRows
        });
    };

    render() {
        const {
            className = 'grid-wrapper'
          } = this.props;
        return (
            <div style={{height:'100%'}}>
                <div style={{color:"white",fontSize: '21px',
                    marginTop: '10px',
                    marginLeft: '10px',marginBottom: '10px'}}>TODO List
                    <div style={{float: 'right'}}>
                        <Switch
                            checked={this.state.isRealtimeMode}
                            style={{color:"white",marginRight: '10px'}}
                            checkedChildren="Realtime"
                            unCheckedChildren="Static"
                            defaultChecked
                            onChange={this.onRealtimeModeChange}
                        />
                    </div>
                </div>
                <div className={`ag-theme-balham-dark ${className}`} style={{ flexGrow: 1,height:'95%' }}>
                    <AgGridReact
                    // properties
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.data}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        suppressAggFuncInHeader={true}
                        animateRows={true}
                        enableRangeSelection={true}
                        rowGroupPanelShow={false}
                        getContextMenuItems={this.getContextMenuItems}
                        groupDefaultExpanded={this.state.groupDefaultExpanded}
                        rowSelection="multiple"
                        onGridReady={this.onGridReady}
                        onRowDoubleClicked={this.onRowDoubleClick}
                        onSelectionChanged={this.onSelectionChanged}
                    />
                </div>
            </div>
        )
    }
}

export default TodoListPanel;