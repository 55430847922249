import { createReducer } from 'common/utils/reducerUtils';
import { userManageGridColumnMap } from '../userManage/components/GridColumnMap';
import EntityMap from 'entities/EntityMap';
import moment from 'moment';
import React, {Component} from 'react';
import {
 
  LOAD_EXTERNAL_SOURCE_SUCCESS,
  LOAD_EXTERNAL_SOURCE_FAILURE,
  LOAD_BROKER_SOURCE_SUCCESS,
  LOAD_BROKER_SOURCE_FAILURE,
  CHANGE_EXTERNAL_SOURCE_MODAL_INPUT,
  OPEN_EXTERNAL_SOURCE_MODAL_INPUT,
  CLOSE_EXTERNAL_SOURCE_MODAL_INPUT,
  SUBMIT_EXTERNAL_SOURCE_SUCCESS,
  SUBMIT_EXTERNAL_SOURCE_FAILURE
} from './sourceManageConstants';
import { Select } from 'antd';

import {

  GET_USER_ACCOUNT_SUCCESS,

} from '../applicationManage/applicationManageConstants';

const Option = Select.Option;
const initialState = {
  isLoaded: false,
  isBrokerLoaded: false,
  userManageGridColumns: EntityMap.map(userManageGridColumnMap),
  allUsers: [],
  allExternalSource: [],
  pmManageList: [],
  userManageQueryParams: {
    isActive: ''
  },
  externalSourceQueryParams: {
    isActive: ''
  },
  
  userManageControlModal: {
    detailData: {
      isActive: 1,
      password: 'abc.1234',
      firm: 'PINPOINT',
      joinDate:  moment()
    },
    mode: null,
    isOpened: false,
    submitDataStatus: 'READY',
    submitErrorMsg: null,
    userChangeLogList: []
  },
  externalSourceControlModal:{
    detailData: {
      isActive: 1
    },
    mode: null,
    isOpened: false,
    submitDataStatus: 'READY',
    submitErrorMsg: null,
    userChangeLogList: []
  },
  userAccountArray: [],
  userAccountOptions: [],
};






export function initiateLoaderStatus(state) {
  return {
    ...state,
    isLoaded: false
  };
}
export function initiateBrokerLoaderStatus(state) {
  return {
    ...state,
    isBrokerLoaded: false
  };
}

function loadExternalSourceSuccess(state,data) {
  return {
    ...state,
    allExternalSource: data.resultData,
    isLoaded: true
  };
}

function loadExternalSourceFailure(state,err) {
  return {
    ...state,
    isLoaded: true
  };
}
function loadBrokerSourceSuccess(state,data) {
  return {
    ...state,
    allExternalSource: data.resultData,
    isBrokerLoaded: true
  };
}

function loadBrokerSourceFailure(state,err) {
  return {
    ...state,
    isBrokerLoaded: true
  };
}


export function changeExternalSourceControlModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.externalSourceControlModal,
    [name]: value
  };

  return {
    ...state,
    externalSourceControlModal: updatedFields
  };
}

export function openExternalSourceControlModal(state) {
  const updatedFields = {
    ...state.externalSourceControlModal,
    isOpened: true
  };

  return {
    ...state,
    externalSourceControlModal: updatedFields
  };
}

export function closeExternalSourceControlModal(state) {
  const updatedFields = {
    ...state.externalSourceControlModal,
    isOpened: false,
    detailData: initialState.externalSourceControlModal.detailData,
    submitDataStatus: 'READY',
    submitErrorMsg: null,
    userChangeLogList: [],
    userAccessLogList: [],
  };

  return {
    ...state,
    externalSourceControlModal: updatedFields
  };
}

function submitExternalSourceSuccess(state,data) {
  const updatedFields = {
    ...state.externalSourceControlModal,
    submitDataStatus: 'SUCCESS',
    submitErrorMsg: null
  };
  return {
    ...state,
    externalSourceControlModal: updatedFields
  };
}

function submitExternalSourceFailure(state,err) {
  let msg = null;
  if(err){
    msg = err['message'];
  }
  const updatedFields = {
    ...state.externalSourceControlModal,
    submitDataStatus: 'ERROR',
    submitErrorMsg: msg
  };
  return {
    ...state,
    externalSourceControlModal: updatedFields
  };
}
// Load User Account
function getUserAccountListSuccess(state, resp) {
  const userAccountArray = resp.data;
  const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);

  return {
    ...state,
    userAccountArray,
    userAccountOptions,
  };
}
export default createReducer(initialState, {


  [LOAD_EXTERNAL_SOURCE_SUCCESS]: loadExternalSourceSuccess,
  [LOAD_EXTERNAL_SOURCE_FAILURE]: loadExternalSourceFailure,

  [LOAD_BROKER_SOURCE_SUCCESS]: loadBrokerSourceSuccess,
  [LOAD_BROKER_SOURCE_FAILURE]: loadBrokerSourceFailure,

  [CHANGE_EXTERNAL_SOURCE_MODAL_INPUT]: changeExternalSourceControlModalInput,
  [OPEN_EXTERNAL_SOURCE_MODAL_INPUT]: openExternalSourceControlModal,
  [CLOSE_EXTERNAL_SOURCE_MODAL_INPUT]: closeExternalSourceControlModal,
  [SUBMIT_EXTERNAL_SOURCE_SUCCESS]: submitExternalSourceSuccess,
  [SUBMIT_EXTERNAL_SOURCE_FAILURE]: submitExternalSourceFailure,

  [GET_USER_ACCOUNT_SUCCESS]: getUserAccountListSuccess,
});
