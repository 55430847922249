import React, { Component } from 'react';
import { Tabs } from 'antd';
import UserManageGrid from './UserManageGrid'
import PmMagagerListGrid from './PmMagagerListGrid'
import PermissionMagagerListGrid from './PermissionMagagerListGrid'
import RoleMagagerListGrid from './RoleMagagerListGrid'
import {
  isAdminRole
} from 'common/utils/DomainUtils';
import './UserManageDashboard.css';

const TabPane = Tabs.TabPane;
class UserManageDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
  }

  tabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  isAdmin = () => {
    const { user } = this.props;
    return isAdminRole(user.role);
  }

  render() {
    return (
      <div className="userMangerDashboardWrapper">
        <Tabs
          tabPosition={'top'}
          onChange={this.tabChange}
          activeKey={this.state.activeTab}
        >
          <TabPane tab="User Manager" key="1">
            <UserManageGrid {...this.props} />
          </TabPane>
              {this.isAdmin() && <TabPane tab="PM Book Manager View" key="2">
                <PmMagagerListGrid {...this.props} />
              </TabPane>}
              {this.isAdmin() && <TabPane tab="Role Manager" key="3">
                <RoleMagagerListGrid {...this.props} />
              </TabPane>}
              {this.isAdmin() && <TabPane tab="Permission Manager" key="4">
                <PermissionMagagerListGrid {...this.props} />
              </TabPane>}
        </Tabs>
        
      </div>
    );
  }
}

export default UserManageDashboard;
