const NEW = 'NEW';
const UPDATE = 'UPDATE';
const DETAIL = 'DETAIL';
const RESET_PWD = 'RESET_PWD';

export default {
  NEW,
  UPDATE,
  DETAIL,
  RESET_PWD
};
