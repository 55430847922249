import EntityMap from 'entities/EntityMap';

const brokerScoreGridColumns = [
    {
        field: 'votingMonth',
        headerName: 'Voting Month',
        cellClass: 'non-number',
        enableRowGroup: true,
        rowGroup: true,
        hide: true
    },
    {
        field: 'brokerName',
        headerName: 'Broker Name',
        cellClass: 'keyword',
        enableRowGroup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        field: 'score',
        headerName: 'Score',
        cellRenderer: 'ratingBarComponent',
        cellClass: 'number'
    },
    {
        field: 'pmName',
        headerName: 'Created by',
        cellClass: 'non-number',
        enableRowGroup: true
      },
    {
        field: 'id',
        headerName: 'Id',
        cellClass: 'number',
        hide: true
    }
];


export const brokerScoreGridColumnMap = EntityMap.fromArray(
    brokerScoreGridColumns,
    'field'
);
