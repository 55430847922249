import { connect } from 'react-redux';

import UserManageDashboard from '../components/UserManageDashboard';

import {
  loadAllUser,
  changeUserManageControlModalInput,
  openUserManageControlModal,
  closeUserManageControlModal,
  submitUser,
  getUserAccountChangeLog,
  getUserAccountAccessLog,
  getPmManageList
} from '../userManageActions';

const mapStateToProps = state => {
  const user = state.settings.user;

  return {
    user,
    isLoaded: state.userManage.isLoaded,
    userManageGridColumns: state.userManage.userManageGridColumns,
    userManageQueryParams: state.userManage.userManageQueryParams,
    allUsers: state.userManage.allUsers,
    userManageControlModal: state.userManage.userManageControlModal,
    pmManageList: state.userManage.pmManageList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAllUser: params => dispatch(loadAllUser(params)),
    changeUserManageControlModalInput: input => dispatch(changeUserManageControlModalInput(input)),
    openUserManageControlModal: () => dispatch(openUserManageControlModal()),
    closeUserManageControlModal: () => dispatch(closeUserManageControlModal()),
    submitUser: (params, queryParams) => dispatch(submitUser(params, queryParams)),
    getUserAccountChangeLog: params => dispatch(getUserAccountChangeLog(params)),
    getUserAccountAccessLog: params => dispatch(getUserAccountAccessLog(params)),
    getPmManageList: params => dispatch(getPmManageList(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManageDashboard);
