const defaultRoutes = [
  {
    code: 'userManger',
    path: '/userManger',
    exact: false,
    category: 'USER_MANAGE',
    label: 'User Manger',
    //icon: 'line graph',
    visible: true
  },
  {
    code: 'Application Manage',
    path: '/applicationManage',
    exact: false,
    category: 'USER_MANAGE',
    label: 'Application Manage',
    //icon: 'credit card',
    visible: true
  },
  {
    code: 'Source Manage',
    path: '/sourceManage',
    exact: false,
    category: 'USER_MANAGE',
    label: 'Source Manage',
    //icon: 'credit card',
    visible: true
  },
  {
    code: 'Score',
    path: '/scoreSystem',
    exact: false,
    category: 'SCORE',
    label: 'Broker Score',
    //icon: 'credit card',
    visible: true
  },
];

export default function configRoutes(category) {
  return defaultRoutes.filter(r => r.category === category)
    .map(dr => {
      return {
        ...dr
      };
    })
    .filter(Boolean);
}
