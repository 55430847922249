import React, { Component } from 'react';
import { List,Input } from 'antd';
import _ from 'lodash';

class SystemHrefPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterStr: ''
        }
    }

    openNewPage = url => {
        window.open(url);
    } 

    _getData = () => {
        const {filterStr} = this.state;
        const data = this.props.user;
        if(data && data.applicationList){
            let result = data.applicationList;
            if(!_.isEmpty(filterStr)){
                result = _.filter(result, function(o) { 
                    return o.name.indexOf(filterStr)>=0; 
                })
            }
            result = _.isEmpty(result)?[]:result
            return result;
        }
        return [];
    }

    onInputChangeEventHandle = (event) => {
        const filedValue = event.target.value;
        this.setState({
            filterStr:  filedValue
        })
    };

    render() {
       const data = this._getData();
       return (
           <div style={{height: "100%", width:"100%",overflow: "auto"}}>
                <style>
                {`
                    .contentDiv:hover{
                      box: flex !important;
                      box-shadow: 10px 10px 5px;
                    }
                `}
                </style>
                <div style={{textAlign:'right'}}>
                    <Input 
                        style={{width: '300px'}}
                        placeholder="Search application"
                        onChange={e=>{
                            this.onInputChangeEventHandle(e);
                        }}
                    />
                </div>
               <div style={{textAlign: "center",margin: '50px' }}>
                <List
                   grid={{ gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 4 }}
                   dataSource={data}
                   renderItem={item => (
                       <List.Item>
                            <div className="contentDiv"  onClick={()=>{
                                this.openNewPage(item.url);
                            }}>
                                <span>
                                    <img src={item.image} height="150px" alt="img" width="80%" />
                                </span>
                                <p style={{fontSize:'18px',color: "white"}}>{item.name}</p>
                            </div>
                       </List.Item>
                   )}
                />
               </div>
               
           </div>
       )
    }
}

export default SystemHrefPanel;