import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {

    queryTaskList: () => {
        const url = `${config.api.AUTH_URL}/task/queryTaskList`;
        return apiClient.post(url);
    },

    finishTaskList: (params) => {
        const url = `${config.api.AUTH_URL}/task/finishTask`;
        return apiClient.post(url,params);
    },

};

export default client;
