import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import ReactDOMServer from 'react-dom/server';
import 'moment/locale/zh-cn';
import hotTableUtils from 'common/ui/hotTableUtils';
import _ from 'lodash';

class BrokerDialogGrid extends Component {
  constructor(props) {
    super(props);
    this.colorMap = {
      1: '#0099FF',
      0: '#FF3333',
      DEFAULT: '#FF00FF'
    };
    this.valueFormatMap = {
      1: 'OffShore',
      0: 'OnShore',
    };
    this.state = {
      hotTableSetting: hotTableUtils.createSettings({
        columns: [
          {
            data: 'brokerName',
            headerName: 'Broker',
            readOnly: true,
            allowEmpty: false,
            width: 60
          },
          {
            data: 'offShore',
            headerName: 'Type',
            readOnly: true,
            allowEmpty: false,
            renderer: this.colorHtmlRenderer,
            width: 40
          },
          {
            data: 'score',
            headerName: 'Score',
            allowEmpty: false,
            readOnly: false,
            width: 150
          },
        ]
      })
    }
  }

  colorHtmlRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //get color
    var color = this.colorMap[value];
    if (!color) {
      color = this.colorMap.DEFAULT;
    }
    const valueFormat = this.valueFormatMap[value];
    const innerHtml = ReactDOMServer.renderToString(
      <p
        style={{
          backgroundColor: color,
          width: '100%',
          color: 'black',
          textAlign: 'center'
        }}
      >
        {valueFormat}
      </p>
    );

    Handsontable.renderers.HtmlRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      innerHtml,
      cellProperties
    );
  };

  afterChangeHandle = (value, action) => {
    if (action === 'edit') {
      const current = value[0];
      if (value) {
        const index = current[0];
        const data = this.data[index];
        data.op = 'U';
      }
    }
    if (action === 'Autofill.fill') {
      value.forEach(item => {
        const index = item[0];
        const data = this.data[index];
        data.op = 'U';
      });
    }
  };

  render() {
    const { data } = this.props;
    this.data = _.orderBy(data, ['offShore'], ['desc']);
    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

            .ant-calendar-picker {
              width: 100%;
            }

            .ant-input {
              height: 100%;
            }

            .ant-picker {
              margin-left:10px;
            }

            .ui.selection.dropdown > .dropdown.icon {
              line-height: 100%;
            }

            .buttonDiv {
              display: flex;
              justify-content: flex-end;
            }
          `}
        </style>
        <HotTable
          {...this.state.hotTableSetting}
          data={this.data}
          autoColumnSize={true}
          manualColumnResize={true}
          rowHeaders={false}
          dragToScroll={false}
          width="100%"
          height="250px"
          afterChange={this.afterChangeHandle}
          readOnly={true}
          licenseKey="non-commercial-and-evaluation"
        />
      </div>
    );
  }
}

export default BrokerDialogGrid;
