import React, { Component } from 'react';
import { Header, Modal,  } from 'semantic-ui-react';
import client from 'features/userManage/api/client'
import { Button, Form, Row, Col, Input } from 'antd'
import OperationtMode from 'common/utils/OperationtMode';

class UserSettingDialog extends Component {

  constructor(props){
    super(props);
    this.state = {
      userInfo: null,
      status: 'READY'
    }
  }

  loadUser = async () => {
    const userInfo = await client.getUser();
    this.setState({
      userInfo
    });
  }

  onInputChangeEventHandle = (event, fieldName) => {
    const { settings } = this.props;
    const { user } = settings.userSettingModal;
    const { userInfo } = this.state;
    const userData = userInfo?userInfo:user;
    const filedValue = event.target.value;
    const updateData = {
      ...userData,
      [fieldName]: filedValue
    }
    this.setState({
      userInfo: updateData
    })
  };

  submitForm = () => {
    this.formRef.current.submit();
  }

  _submitData = async () => {
    const { resetUserSettingDialog } = this.props;
    const {
      userInfo,
    } = this.state;
    const submitData = {
      ...userInfo,
      op: OperationtMode.UPDATE
    }
    await client.submitUser(submitData);
    resetUserSettingDialog();
    await this.loadUser();
  }

  _createForm = () => {
    const { settings } = this.props;
    const { user } = settings.userSettingModal;
    const {
      userInfo,
    } = this.state;
    const userData = userInfo?userInfo:user;
    this.formRef = React.createRef();
    return (
    <Form
      ref={this.formRef}
      className="ant-advanced-search-form"
      onFinish={this._submitData}
      initialValues={userData}
    >
      <Row>
        <Col span={20}>
          <Form.Item
             name={'englishName'}
             label={'English Name'}
          >
              <Input disabled={true}/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Form.Item
             name={'chineseUserName'}
             label={'Real Name'}
          >
              <Input 
                onChange={e=>{
                  this.onInputChangeEventHandle(e,'chineseUserName');
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Form.Item
             name={'title'}
             label={'Title'}
          >
              <Input 
                onChange={e=>{
                  this.onInputChangeEventHandle(e,'title');
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item
             name={'directLine'}
             label={'Direct Line'}
          >
              <Input 
                onChange={e=>{
                  this.onInputChangeEventHandle(e,'directLine');
              }}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
             name={'phoneExt'}
             label={'Phone Ext'}
          >
              <Input 
                onChange={e=>{
                  this.onInputChangeEventHandle(e,'phoneExt');
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Form.Item
             name={'handPhone'}
             label={'Hand Phone'}
          >
              <Input 
                onChange={e=>{
                  this.onInputChangeEventHandle(e,'handPhone');
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col span={20}>
          <Form.Item
             name={'joinDate'}
             label={'Join Date'}
          >
              <DatePicker 
                allowClear={'false'}
                disabled={true}
                style={{width:'100%'}}
              />
          </Form.Item>
        </Col>
      </Row> */}
      <Row>
        <Col span={20}>
          <Form.Item
             name={'email'}
             label={'Email'}
          >
              <Input disabled={true}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
    );
  }

  render() {
    const { settings, resetUserSettingDialog } = this.props;
    const { userSettingModal } = settings;
    const { status } = this.state; 

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }
            .ui.basic.label {
              width: 55px;
            }
            .ui.modal .six {
              height: 350px;
            }
            .ui.modal .six {
              overflow-y: scroll;
            }
          `}
        </style>
        <Modal size="small" open={userSettingModal.isOpened}>
          <Header icon="question circle" content="User Setting" />
          <Modal.Content>
            {this._createForm()}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={resetUserSettingDialog} >Cancel</Button>
            {( {
                ERROR: (
                  <Button onClick={this.submitForm}>
                     Submit Fail - Retry?
                  </Button>
                ),
                READY: (
                  <Button onClick={this.submitForm}>
                      Submit
                  </Button>
                )
              }[status])}
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default UserSettingDialog;
