import moment from 'moment';

export const PAPER_TRADE_FUNDS = ['PTF', 'CPTF'];
export const ALPHA_CAPTURE_FUNDS = ['ACF', 'CACF'];
export const EXTERNAL_FUNDS = ALPHA_CAPTURE_FUNDS.concat(PAPER_TRADE_FUNDS);
const ADMIN_ROLES = ['Admin', 'Supervisor'];
const PM_ROLES = ['PortMngr', 'Supervisor', 'FundMngr'];
const QUANT_BOOKS = ['T34', 'T12', 'W00', 'W08', 'T45', 'T22'];

export const isInternalFund = fund => {
  return !EXTERNAL_FUNDS.includes(fund);
};

export const isQuantBook = book => {
  return QUANT_BOOKS.includes(book);
};

export const isPaperTradeFund = fund => {
  return PAPER_TRADE_FUNDS.includes(fund);
};

export const isAlphaCaptureFund = fund => {
  return ALPHA_CAPTURE_FUNDS.includes(fund);
};

export const isAdminRole = role => {
  return ADMIN_ROLES.includes(role);
};

export const canPlaceOrder = role => {
  return ADMIN_ROLES.includes(role) || PM_ROLES.includes(role);
};

export const isInternalUser = user => {
  return user && user.firm === 'PINPOINT';
};

export const disabledDate = (date, user = {}) => {
  if (isAdminRole(user.role)) return false;

  const minDate = moment.min(
    moment().startOf('year'),
    moment().subtract(1, 'months')
  );
  return date < minDate;
};
