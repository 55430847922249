export const LOAD_ALL_APPLICATION_FAILURE = 'LOAD_ALL_APPLICATION_FAILURE';
export const LOAD_ALL_APPLICATION_SUCCESS = 'LOAD_ALL_APPLICATION_SUCCESS';
export const INITIATE_LOADER_STATUS = 'INITIATE_LOADER_STATUS';
export const OPEN_APPLICATION_MANAGE_MODAL_INPUT = 'OPEN_APPLICATION_MANAGE_MODAL_INPUT';
export const CLOSE_APPLICATION_MANAGE_MODAL_INPUT = 'CLOSE_APPLICATION_MANAGE_MODAL_INPUT';
export const ADD_ROLE_APPLICATION_CONFIG_SUCCESS = 'ADD_ROLE_APPLICATION_CONFIG_SUCCESS';
export const ADD_ROLE_APPLICATION_CONFIG_FAILURE = 'ADD_ROLE_APPLICATION_CONFIG_FAILURE';
export const GET_USER_ACCOUNT_FAILURE = 'GET_USER_ACCOUNT_FAILURE';
export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS';
export const QUERY_TASK_LIST_FAILURE = 'QUERY_TASK_LIST_FAILURE';
export const QUERY_TASK_LIST_SUCCESS = 'QUERY_TASK_LIST_SUCCESS';
