import {
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  OPEN_USER_SETTINGS_DIALOG,
  RESET_USER_SETTINGS_DIALOG,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILURE
} from './settingConstants';
import {
  initHomepage
} from 'features/homePage/homePageActions';
import client from './api/client';
import { logout } from 'features/auth/authActions';

function loadSettingsSuccess(settings) {
  return {
    type: LOAD_SETTINGS_SUCCESS,
    payload: settings
  };
}

function loadSettingsFailure(err) {
  return {
    type: LOAD_SETTINGS_FAILURE,
    payload: err
  };
}

export function loadSettings() {
  return function(dispatch) {
    client
      .loadSettings()
      .then(resp => {
        dispatch(loadSettingsSuccess(resp));
        dispatch(initHomepage(resp))
      })
      .catch(err => {
        dispatch(loadSettingsFailure(err));
        dispatch(logout());
      });
  };
}

export function openUserSettingDialog() {
  return {
    type: OPEN_USER_SETTINGS_DIALOG
  };
}

export function resetUserSettingDialog() {
  return {
    type: RESET_USER_SETTINGS_DIALOG
  };
}

function loadUserSuccess(data) {
  return {
    type: LOAD_USER_SUCCESS,
    payload: data
  };
}

function loadUserFailure(err) {
  return {
    type: LOAD_USER_FAILURE,
    payload: err
  };
}

export function loadUser() {
  return function(dispatch) {
    client
      .getUser()
      .then(resp => {
        dispatch(loadUserSuccess(resp));
      })
      .catch(err => {
        dispatch(loadUserFailure(err));
      });
  };
}