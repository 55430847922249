export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHG_PWD_REQUEST = 'CHG_PWD_REQUEST';
export const CHG_PWD_SUCCESS = 'CHG_PWD_SUCCESS';
export const CHG_PWD_FAILURE = 'CHG_PWD_FAILURE';
export const CHG_PWD_RESET = 'CHG_PWD_RESET';
export const CHG_LOGIN_STATUS = 'CHG_LOGIN_STATUS';
export const LS_KEY_ACCESS_TOKEN = 'auth-token';
export const LS_KEY_USER_TOKEN = 'ut';
