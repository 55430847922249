import React, { Component } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, Loader } from 'semantic-ui-react';
import {Input,Select,DatePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import OperationtMode from 'common/utils/OperationtMode';
import client from '../api/client';
import {
  isAdminRole
} from 'common/utils/DomainUtils';
import ExternalSourceDialog from './ExternalSourceDialog'
import { externalSourceListColumn } from './GridColumnMap';
const Option = Select.Option;
class ExternalSourceGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columnDefs: externalSourceListColumn,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        cellClass: 'keyword',
        width: 120
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedData: null,
      externalSourceQueryParams: {
        externalType: "EXTERNAL",
        isAdmin: this.isAdmin(),
      },
      data: []
    };
  }

  componentDidMount(){
    this.loadExternalSourceList();
  }

  loadExternalSourceList = () =>{
    // this.props.loadAllUser(this.state.externalSourceQueryParams);
    client.loadExternalSource(this.state.externalSourceQueryParams).then(resp => {
      this.setState({
        data: resp.resultData
      })
    });
  }

  onQueryParamsChange = (event, fieldName , directFlag) => {
    const {externalSourceQueryParams} = this.state;
    const filedValue = directFlag?event:event.target.value;
    const updateData = {
      ...externalSourceQueryParams,
      [fieldName]: filedValue 
    }
    this.setState({
      externalSourceQueryParams: updateData
     });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  addUser = () => {
    this.props.changeExternalSourceControlModalInput({name: 'mode',value: OperationtMode.NEW}); 
    this.props.openExternalSourceControlModal()
  }

  updateUser = () => {
    if(this.state.selectedData){
      this.props.changeExternalSourceControlModalInput({name: 'mode',value: OperationtMode.UPDATE}); 
      this.props.changeExternalSourceControlModalInput({name: 'detailData',value: this.getSelectData()}); 
      this.props.openExternalSourceControlModal()
    }
  }

  

  getSelectData = () =>{
    const {selectedData} = this.state;
    let purchaseDate = this.state.selectedData.purchaseDate;
    let expireDate = this.state.selectedData.expireDate;
    if(purchaseDate){
      purchaseDate = moment(purchaseDate,'YYYY-MM-DD');
    }
    if(expireDate){
      expireDate = moment(expireDate,'YYYY-MM-DD');
    }
    return {
      ...selectedData,
      expireDate,
      purchaseDate
    }
  }

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows){
      this.setState({
        selectedData: selectedRows[0]
      })
    }else{
      this.setState({
        selectedData: null
      })
    }
  };

  

  _buildPermissionsCheckKeys = (permissionsCheckKeys) =>  {

  }

 

  _closeModal = () => {
    const { userRolePermissionModal } =this.state;
    this.setState({
      userRolePermissionModal: {
        ...userRolePermissionModal,
        roles: [],
        roleCheckedKeys: [],
        permissions: [],
        permissionsCheckKeys: [],
        isOpened: false,
      },
    })
  }

 

  isAdmin = () => {
    const { user } = this.props;
    return isAdminRole(user.role);
  }

  submitData = (data) => {

    this.props.submitExternalSource(data, this.state.externalSourceQueryParams);
  }

  render() {
    const {
      
      allUsers,
      isLoaded = true,
      className = 'grid-wrapper',
      userAccountOptions,
    } = this.props;
    const {data: allExternalSource} =this.state;
    const {externalSourceQueryParams} = this.state;
    const data = _.isEmpty(allExternalSource)?[]:allExternalSource.map(r => ({
      ...r,
      // englishName: r.englishName?r.englishName.toLowerCase():r.englishName
    }))
    const sortedRows = _.orderBy(data, ['sourceId'], ['asc']);
    const total = _.isEmpty(sortedRows)? 0:sortedRows.length;
    return (
      <div style={{width:'100%',height:'95%', display: 'flex' , flexDirection: 'column'}}>
        <div>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '2px'
            }}
          >
            {/* <Input
              placeholder="Input EnglishName..."
              size="middle"
              style={{width:'180px'}}
              // value={externalSourceQueryParams.englishName}
              onChange={e => {
                this.onQueryParamsChange(e, 'userName');
              }}
              onPressEnter={this.loadExternalSourceList}
            />  */}
            <Input
              placeholder="Input SourceName..."
              size="middle"
              style={{width:'180px'}}
              // value={externalSourceQueryParams.englishName}
              onChange={e => {
                this.onQueryParamsChange(e, 'sourceName');
              }}
              onPressEnter={this.loadExternalSourceList}
            /> 
            <Select
              size="middle"
              style={{width:'120px',marginLeft: '5px'}}
              defaultValue={""}
              onChange={value => {
                this.onQueryParamsChange(value, 'sourceType' , true);
              }}
            > 
                <Option value="">ALL</Option>
                <Option value="Terminal">Terminal</Option>
                <Option value="DataProvider">DataProvider</Option>
                <Option value="Broker">Broker</Option>
            </Select>


            <Button
              size="mini"
              content="Reload"
              color="green"
              // disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px', marginLeft: '10px' }}
              onClick={this.loadExternalSourceList}
            />
              {/* <Button
                  size="mini"
                  content="Add"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px' }}
                  onClick={this.addUser}
                  />

                <Button
                  size="mini"
                  content="Update"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px' }}
                  onClick={this.updateUser}
                /> */}

                {/* { <Button
                  size="mini"
                  content="Reset PWD"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this.resetUserPwd}
                />}

                {<Button
                  size="mini"
                  content="Permissions"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this._openPermissionModal}
                />}

                <Button
                  size="mini"
                  content="Detail"
                  color="green"
                  disabled={!isLoaded}
                  style={{ marginBottom: '4px', marginTop: '4px'}}
                  onClick={this.detailUser} 
                /> */}
          </div>
        </div>
          {/* {!isLoaded ? (
              <Loader active inverted content="Loading..." />
            ) : ( */}
              <div className={`ag-theme-balham-dark ${className}`} style={{ flexGrow: 1 }}>
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={sortedRows}
                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                    suppressAggFuncInHeader={true}
                    animateRows={true}
                    enableRangeSelection={true}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    groupDefaultExpanded={this.state.groupDefaultExpanded}
                    pivotPanelShow={this.state.pivotPanelShow}
                    rowSelection="single"
                    context={this.state.context}
                    sideBar={this.state.sideBar}
                    onGridReady={this.onGridReady}
                    onSelectionChanged={this.onSelectionChanged}
                  />
               </div>
            {/* )}  */}
            <div style={{width: '100%',
                textAlign: 'right', 
                fontSize: '18px',
                height: '30px',
                marginTop: '5px'}}>
              <span style={{color: '#0099CC', marginRight: '5px'}}>Total:</span> 
              <span style={{color: '#969696', marginRight: '15px' }}>{total}</span>
            </div>


          {/* <ExternalSourceDialog
            {...this.props}
            submitData={this.submitData}
          /> */}
      </div>
    );
  }
}
  
export default ExternalSourceGrid;