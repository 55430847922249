import React from 'react';
class NoPermission extends React.Component {
  render() {
    return (
        <div></div>
    );
  }
}

export default NoPermission;
