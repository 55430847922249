import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';

import './App.css';
import 'antd/dist/antd.css';
import 'react-awesome-query-builder/css/styles.scss';
import 'ant-design-draggable-modal/dist/index.css';

import PrivateRoute from 'common/ui/PrivateRoute';
import LoginFormContainer from 'features/auth/containers/LoginFormContainer';
import LogoutContainer from 'features/auth/containers/LogoutContainer';
import ChgPwdFormContainer from 'features/auth/containers/ChgPwdFormContainer';
import HomePageContainer from 'features/homePage/containers/HomePageContainer';
import UserManageContainer from 'features/userManage/containers/UserManageContainer'
import ApplicationManageContainer from 'features/applicationManage/containers/ApplicationManageContainer'
import ScoreContainer from 'features/score/containers/ScoreContainer'
import TopNavBar from 'features/layout/components/TopNavBar';
import SideNavBar from 'features/layout/components/SideNavBar';
import { toggleSidebar } from 'features/layout/layoutActions';
import client  from "features/auth/api/client";
import {localStorageClient} from "common/api/localStorageClient"
import {LS_KEY_USER_TOKEN} from "features/auth/authConstants";
import UserSettingDialog from "features/settings/components/UserSettingDialog";
import SourceContainer from 'features/sourceManage/containers/SourceManageContainer'
import {
  openUserSettingDialog,
  resetUserSettingDialog
} from 'features/settings/settingActions';

class App extends Component {

  //页面挂载后开始轮询,每隔10秒
  componentDidMount(){
    this.timer=setInterval(()=>{
      const accessToken = localStorageClient.getFromCookie(LS_KEY_USER_TOKEN);
      if(accessToken){
        client.checkUserStatus().then(response=>{
          if(response && response.code === "100"){
            window.location.href = response.url;
          }
        });
      }
    },30000);  
  }

  componentWillUnmount(){
    if(this.timer){
        clearInterval(this.timer);
    }
  }

  render() {
    const {
      toggleSidebar,
      settings,
      isSidebarOpen,
      showSideNavBar,
      showScoreSideNavBar,
      openUserSettingDialog,
      resetUserSettingDialog,
      auth: { isLoggedIn, accessToken }
    } = this.props;
    return (
        <div className="box">
        
          {
            showSideNavBar?(
              <div>
                <Dimmer
                  page
                  inverted
                  active={isSidebarOpen}
                  onClick={toggleSidebar}
                  style={{ zIndex: 100 }}
                />
                <SideNavBar
                  category={'USER_MANAGE'}
                  isOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  routes={settings.routes}
                />
              </div>
            ):("")
          }

          {   
            showScoreSideNavBar?(
              <div>
                <Dimmer
                  page
                  inverted
                  active={isSidebarOpen}
                  onClick={toggleSidebar}
                  style={{ zIndex: 100 }}
                />
                <SideNavBar
                  category={'SCORE'}
                  isOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  routes={settings.routes}
                />
              </div>
            ):("")
          }
          

          <div className="row header">
              <TopNavBar
                  toggleSideBar={toggleSidebar}
                  openUserSettingDialog={openUserSettingDialog}
                  isLoggedIn={isLoggedIn}
                  user={settings.user || {}}
                  accessToken={accessToken}
              />
          </div>

          <div className="row content">
            <Switch>
              <PrivateRoute
                  path="/userManger"
                  isLoggedIn={isLoggedIn}
                  component={UserManageContainer}
                  {...this.props}
              />
              <PrivateRoute
                  path="/applicationManage"
                  isLoggedIn={isLoggedIn}
                  component={ApplicationManageContainer}
                  {...this.props}
              />
              <PrivateRoute
                  path="/scoreSystem"
                  isLoggedIn={isLoggedIn}
                  component={ScoreContainer}
                  {...this.props}
              />
              <PrivateRoute
                  path="/sourceManage"
                  isLoggedIn={isLoggedIn}
                  component={SourceContainer}
                  {...this.props}
              />
              <Route path="/login" component={LoginFormContainer} />
              <Route path="/logout" component={LogoutContainer} />
              <Route path="/chgpwd" component={ChgPwdFormContainer} />
              <Route path="/setting" component={ChgPwdFormContainer} />
              <PrivateRoute
                  path="/"
                  isLoggedIn={isLoggedIn}
                  component={HomePageContainer}
                  {...this.props}
              />
            </Switch>
            <UserSettingDialog settings={settings} resetUserSettingDialog={resetUserSettingDialog} />
          </div>

          {/* <div className="row statusbar">
          <span className="statusInfo">当前日期: { moment().format('YYYY-MM-DD') }</span>
        </div> */}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.layout,
    settings: state.settings,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    openUserSettingDialog: () => dispatch(openUserSettingDialog()),
    resetUserSettingDialog: () => dispatch(resetUserSettingDialog())
  };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
