import { createReducer } from 'common/utils/reducerUtils';
import _ from 'lodash';

import {
  INITIATE_LOADER_STATUS,
  // Broker Score
  LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
  LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
  LOAD_BROKER_SCORE_LIST_SUCCESS,
  LOAD_BROKER_SCORE_LIST_FAILURE,
  SAVE_BROKER_SCORE_SUCCESS,
  SAVE_BROKER_SCORE_FAILURE,
  REMOVE_BROKER_SCORE_SUCCESS,
  REMOVE_BROKER_SCORE_FAILURE,
  INITIATE_WEIGHT_MAP_LOADER_STATUS,
  CHANGE_BROKER_QUERY_PARAMS,
  CHANGE_BROKER_SCORE_MODAL_INPUT,
  LOAD_CORRESPONDENCE_LIST_SUCCESS,
  LOAD_CORRESPONDENCE_LIST_FAILURE,
  OPEN_BROKER_SCORES_DETAIL_DIALOG,
  RESET_BROKER_SCORES_DETAIL_DIALOG,

} from './scoreConstants';
import {
  brokerScoreGridColumnMap
} from './components/GridColumnMap';
import EntityMap from 'entities/EntityMap';
import moment from 'moment';
import OperationtMode from 'common/utils/OperationtMode';

const initialState = {
  isLoaded: false,

  brokerScoreGridColumns: EntityMap.map(brokerScoreGridColumnMap),
  brokerQueryParams: {
    pageIdex: 1,
    pageSize: 5000,
    votingMonthFrom: moment().add('month', -3),
    votingMonthFromStr: `${moment().add('month', -3).year()}-Q${moment().add('month', -3).quarter()}`,
    votingMonthTo: moment(),
    votingMonthToStr: `${moment().year()}-Q${moment().quarter()}`,
    marketList: ['BACK'],
  },
  brokerScoreControlModal: {
    isOpened: false,
    // server err messages
    submitStatus: 'READY',
    mode: OperationtMode.NEW,
    pmName: null,
    votingMonth: moment(),
    weightMap: [{ name: '', code: '', weight: 0 }],
    weightMapLoaded: false,
    errorMsgMap: {}
  },
  brokerScoreListForDetail: [],
};

// initiate loader status
export function initiateLoaderStatus(state) {
  return {
    ...state,
    isLoaded: false
  };
}

//broker
export function loadBrokerScoreListByScoreSuccess(state, result) {
  //handle reuslt
  if (result.data) {
    _.map(result.data, (value, key) => {
      if (value) {
        value.forEach(item => {
          const detailList = item.detailList;
          detailList.forEach(ele => {
            const code = ele['code'];
            item[code] = ele['score'];
          });
        });
      }
    });
  }
  return {
    ...state,
    isLoaded: true,
    brokerScoreListForDetail: result.data
  };
}

export function loadBrokerScoreListByScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadBrokerScoreListSuccess(state, result) {
  //handle reuslt
  return {
    ...state,
    brokerScores: result.data,
    isLoaded: true
  };
}

export function loadBrokerScoreListFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function saveBrokerScoreSuccess(state, result) {
  //handle reuslt
  return {
    ...state,
    brokerScoreControlModal: initialState.brokerScoreControlModal,
    isLoaded: true
  };
}

export function saveBrokerScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeBrokerScoreSuccess(state, result) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeBrokerScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadCorrespondenceListSuccess(state, result) {
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      weightMap: result.data,
      weightMapLoaded: true
    }
  };
}

export function loadCorrespondenceListFailure(state, err) {
  return {
    ...state,
    brokerScoreControlModal: {
      weightMapLoaded: true
    }
  };
}

export function initiateWeightMapLoaded(state) {
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      weightMapLoaded: false
    }
  };
}

export function changeBrokerQureyParams(state, input) {
  const updateData = {
    ...state.brokerQueryParams
  };
  // eslint-disable-next-line array-callback-return
  input.map(item => {
    updateData[item.name] = item.value;
  });
  return {
    ...state,
    brokerQueryParams: updateData
  };
}

export function changeBrokerScoreControlModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.brokerScoreControlModal,
    [name]: value
  };

  return {
    ...state,
    brokerScoreControlModal: updatedFields
  };
}

export function openBrokerScoresDetailDialog(state, votingMonth) {
  var date = moment();
  if (votingMonth) {
    date = moment(votingMonth, 'YYYY-[Q]Q');
  }
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      isOpened: true,
      mode: OperationtMode.NEW,
      submitStatus: 'READY',
      votingMonth: date,
      weightMap: [{ name: '', code: '', weight: 0 }],
      weightMapLoaded: false,
      errorMsgMap: {}
    }
  };
}

// reset BrokerDetail control
export function resetBrokerDetailControlModal(state) {
  const updatedFields = {
    ...initialState.brokerScoreControlModal,
    weightMap: state.brokerScoreControlModal.weightMap
  };
  return {
    ...state,
    brokerScoreControlModal: updatedFields
  };
}

export default createReducer(initialState, {
  [INITIATE_LOADER_STATUS]: initiateLoaderStatus,

  // Broker Score
  //Broker
  [LOAD_BROKER_SCORE_BY_BROKER_SUCCESS]: loadBrokerScoreListByScoreSuccess,
  [LOAD_BROKER_SCORE_BY_BROKER_FAILURE]: loadBrokerScoreListByScoreFailure,
  [LOAD_BROKER_SCORE_LIST_SUCCESS]: loadBrokerScoreListSuccess,
  [LOAD_BROKER_SCORE_LIST_FAILURE]: loadBrokerScoreListFailure,
  [SAVE_BROKER_SCORE_SUCCESS]: saveBrokerScoreSuccess,
  [SAVE_BROKER_SCORE_FAILURE]: saveBrokerScoreFailure,
  [REMOVE_BROKER_SCORE_SUCCESS]: removeBrokerScoreSuccess,
  [REMOVE_BROKER_SCORE_FAILURE]: removeBrokerScoreFailure,
  [LOAD_CORRESPONDENCE_LIST_SUCCESS]: loadCorrespondenceListSuccess,
  [LOAD_CORRESPONDENCE_LIST_FAILURE]: loadCorrespondenceListFailure,
  [INITIATE_WEIGHT_MAP_LOADER_STATUS]: initiateWeightMapLoaded,
  [CHANGE_BROKER_QUERY_PARAMS]: changeBrokerQureyParams,
  [CHANGE_BROKER_SCORE_MODAL_INPUT]: changeBrokerScoreControlModalInput,
  [OPEN_BROKER_SCORES_DETAIL_DIALOG]: openBrokerScoresDetailDialog,
  [RESET_BROKER_SCORES_DETAIL_DIALOG]: resetBrokerDetailControlModal,
 
});
