import { createReducer } from 'common/utils/reducerUtils';
import { applicationManageGridColumnMap } from '../applicationManage/components/GridColumnMap';
import EntityMap from 'entities/EntityMap';
import {
  LOAD_ALL_APPLICATION_FAILURE,
  LOAD_ALL_APPLICATION_SUCCESS,
  OPEN_APPLICATION_MANAGE_MODAL_INPUT,
  CLOSE_APPLICATION_MANAGE_MODAL_INPUT,
  INITIATE_LOADER_STATUS,
  ADD_ROLE_APPLICATION_CONFIG_SUCCESS,
  ADD_ROLE_APPLICATION_CONFIG_FAILURE,
  GET_USER_ACCOUNT_FAILURE,
  GET_USER_ACCOUNT_SUCCESS
} from './applicationManageConstants';

const initialState = {
  isLoaded: false,

  applicationManageGridColumns: EntityMap.map(applicationManageGridColumnMap),
  allApplicationList: [],
  innerUserAccountList:[],
  applicationManageQueryParams: {
    queryUserMode:true
  },
  applicationManageControlModal: {
    isOpened: false
  }
};

export function initiateLoaderStatus(state) {
  return {
    ...state,
    isLoaded: false
  };
}

function getAllApplicationSuccess(state,data) {
  return {
    ...state,
    allApplicationList: data.data,
    isLoaded: true
  };
}

function getAllApplicationFailure(state,err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function openApplicationManageControlModal(state) {
  const updatedFields = {
    ...state.applicationManageControlModal,
    isOpened: true
  };

  return {
    ...state,
    applicationManageControlModal: updatedFields
  };
}

export function closeApplicationManageControlModal(state) {
  const updatedFields = {
    ...state.applicationManageControlModal,
    isOpened: false,
  };

  return {
    ...state,
    applicationManageControlModal: updatedFields
  };
}

export function addRoleApplicationConfigSuccess(state, data) {
  const updatedFields = {
    ...state.applicationManageControlModal,
    isOpened: false,
  };
  return {
    ...state,
    applicationManageControlModal: updatedFields
  };
}

export function addRoleApplicationConfigFailure(state, err) {
  return {
    ...state
  };
}

export function getUserAccountListSuccess(state, data) {
  return {
    ...state,
    innerUserAccountList: data.data
  };
}

export function getUserAccountListFailure(state, err) {
  return {
    ...state
  };
}


export default createReducer(initialState, {
  [INITIATE_LOADER_STATUS]: initiateLoaderStatus,
  [LOAD_ALL_APPLICATION_FAILURE]: getAllApplicationFailure,
  [LOAD_ALL_APPLICATION_SUCCESS]: getAllApplicationSuccess,
  [OPEN_APPLICATION_MANAGE_MODAL_INPUT]: openApplicationManageControlModal,
  [CLOSE_APPLICATION_MANAGE_MODAL_INPUT]: closeApplicationManageControlModal,
  [ADD_ROLE_APPLICATION_CONFIG_SUCCESS]: addRoleApplicationConfigSuccess,
  [ADD_ROLE_APPLICATION_CONFIG_FAILURE]: addRoleApplicationConfigFailure,
  [GET_USER_ACCOUNT_FAILURE]: getUserAccountListFailure,
  [GET_USER_ACCOUNT_SUCCESS]: getUserAccountListSuccess
});
