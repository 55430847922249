import EntityMap from 'entities/EntityMap';

const applicationManageGridColumns = [
    {
        field: 'name',
        headerName: 'Application Name',
        cellClass: 'non-number',
        enableRowGroup: true,
        autoHeight: true,
        rowGroup: true,
        hide: true
    } ,
    {
      field: 'role',
      headerName: 'Role',
      cellClass: 'non-number'
    },
    {
      field: 'englishName',
      headerName: 'English Name',
      cellClass: 'non-number'
    }
  ];

  export const applicationManageGridColumnMap = EntityMap.fromArray(
    applicationManageGridColumns,
    'field'
  );