import React, { Component } from 'react';
import OperationtMode from 'common/utils/OperationtMode';
import 'moment/locale/zh-cn';
import { DatePicker,Form, Row, Col, Input, Button,Select,Tabs } from 'antd';
import { Header, Modal,Message } from 'semantic-ui-react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
// import { 
//   userChangeLogGridColumn,
//   userAccessLogGridColumn } from './GridColumnMap';

const { Option } = Select;
class ExternalSourceDialog extends Component {
  constructor(props) {
    super(props);
    // const _requireRule = this._requireRule,_patternRule = this._patternRule;
    // const pwdReg = new RegExp("(?=.*\\d)(?=.*[A-Za-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$");
    // const pwdMsg = "password must contain English letters & symbols, numbers, and length must >= 8";
    this.state = {
      // columnDefs: userChangeLogGridColumn,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      isActiveList: [{'name': 'Yes','value': 1},{'name': 'No','value': 0}],
      // roleList: [
      //   {'name': 'Admin','value': 'Admin'},
      //   {'name': 'Analyst','value': 'Analyst'},
      //   {'name': 'APM','value': 'APM'},
      //   {'name': 'BD','value': 'BD'},
      //   {'name': 'Client Service','value': 'Client Service'},
      //   {'name': 'Compliance','value': 'Compliance'},
      //   {'name': 'IT','value': 'IT'},
      //   {'name': 'Management','value': 'Management'},
      //   {'name': 'Operation','value': 'Operation'},
      //   {'name': 'PM','value': 'PM'},
      //   {'name': 'Research','value': 'Research'},
      //   {'name': 'Risk','value': 'Risk'},
      //   {'name': 'Trader','value': 'Trader'},
      //   {'name': 'Marketing','value': 'Marketing'},
      //   {'name': 'Trading','value': 'Trading'},
      // ],

      // ownerTypeList: [
      //   {'name': 'Public','value': 'Public'},
      //   {'name': 'Private','value': 'Private'},
      //   {'name': 'MO','value': 'MO'},
      // ],
      sourceTypeList: [
        {'name': 'Terminal','value': 'Terminal'},
        {'name': 'DataProvider','value': 'DataProvider'},
        {'name': 'Broker','value': 'Broker'},
      ],

      // rules: {
      //   'password': [_requireRule('password'),_patternRule(pwdReg,pwdMsg)],
      //   'userAccountName': [_requireRule('User AccountName')],
      //   'englishName': [_requireRule('English Name')],
      //   'email': [_requireRule('Email')],
      //   'firm': [_requireRule('Firm')],
      //   'isActive': [_requireRule('Active')],
      //   'position': [_requireRule('Position')],
      //   'officeLocation': [_requireRule('OfficeLocation')]
      // },
      updateReadOnlyFileds: ["userAccountName","domainName",'englishName'],
      resetCanEditFileds: ["password"]
    };
  }
  saveData = () =>{

  }

  // _requireRule = (name) =>{
  //   return { required: true, message: `Please input ${name}!` }
  // }

  // _patternRule = (pattern,msg) =>{
  //   return {  pattern: pattern, message: msg };
  // }

  _getFields = () => {
    const {
      allUsers,

    } = this.props;
    const {
      mode
    } = this.props.externalSourceControlModal;
    const{rules} = this.state;
    const children = [];
    if(OperationtMode.NEW === mode){
      children.push(this._createNormalInput('sourceName','SourceName',null,null,'Source Name'));
    }
    children.push(this._createNormalSelect('sourceType','SourceType', null , 8, this.state.sourceTypeList, false));
    children.push(this._createNormalUserSelect('ownerId','Owner Name',null , 8, allUsers, false));
    children.push(this._createNormalSelect('ownerType','OwnerType', null , 8, this.state.ownerTypeList, false));
    children.push(this._createNormalInput('purchaseAmount','PurchaseAmount',null,null,'Amount'));
    children.push(this._createNormalInput('purchaseDesc','PurchaseDesc',null,null,'Unit Desc'));
    children.push(this._createNormalDateInput('purchaseDate','PurchaseDate', null , 8));
    children.push(this._createNormalDateInput('expireDate','ExpireDate', null , 8));
       if(OperationtMode.UPDATE === mode){
      children.push(this._createNormalDateInput('leaveDate','Leave Date', null , 8));
    }
    return children;
  }

  _createNormalInput = (name,lable,rules,span,desc) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Input 
            placeholder={desc}
            readOnly={this._readOnly(name)}
            onChange={e=>{
              this.onInputChangeEventHandle(e,name);
            }}
          />
        </Form.Item>
      </Col>);
  }

  _createNormalDateInput = (name,lable,rules,span) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <DatePicker 
            allowClear={'false'}
            disabled={this._readOnly(name)}
            style={{width:'100%'}}
            onChange={(date, dateString)=>{
              this.onValueChangeHandle(dateString,name);
            }}
          />
        </Form.Item>
      </Col>);
  }

  _createNormalPwd = (name,lable,rules,span) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Input.Password 
            readOnly={this._readOnly(name)}
            onChange={e=>{
              this.onInputChangeEventHandle(e,name);
            }}
            />
        </Form.Item>
      </Col>);
  }

  _createNormalSelect = (name,lable,rules,span,options,allowClear) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Select 
            allowClear={allowClear} 
            disabled={this._readOnly(name)}
            onChange={(value) => {
              this.onValueChangeHandle(value, name);
            }}>
            {options.map(item => {
              return (<Option key={item.value} value={item.value}>{item.name}</Option>);
            })}
          </Select>
        </Form.Item>
      </Col>);
  }
  _createNormalUserSelect = (name,lable,rules,span,options,allowClear) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Select 
            allowClear
            showSearch
            disabled={this._readOnly(name)}
            onChange={(value) => {
              this.onValueChangeHandle(value, name);
            }}>
            {options.map(item => {
              // if ('Wang Qiang'.indexOf(item.role)  != -1){
                return (<Option key={item.englishName} value={item.userAccountId}>{item.englishName}</Option>);
              // }
              
            })}
          </Select>
        </Form.Item>
      </Col>);
  }
  onInputChangeEventHandle = (event, fieldName) => {
    const filedValue = event.target.value;
    this.onValueChangeHandle(filedValue,fieldName)
  };

  onValueChangeHandle = (value,name) =>{
    const {
      detailData
    } = this.props.externalSourceControlModal;
    const updateData = {
      ...detailData,
      [name]: value
    }
    this.props.changeUserManageControlModalInput({
      name: 'detailData',
      value: updateData
    });
  }

  _readOnly = (field) =>{
    const {
      mode
    } = this.props.externalSourceControlModal;
    const{
      updateReadOnlyFileds,
      resetCanEditFileds
    } = this.state;
    if(mode===OperationtMode.DETAIL){
      return true;
    }if(mode===OperationtMode.UPDATE && field && updateReadOnlyFileds.indexOf(field)>=0){
      return true;
    }if(mode===OperationtMode.RESET_PWD && field  && resetCanEditFileds.indexOf(field)<0){
      return true;
    }
    return false;
  }

  _submitData = () => {
    const {
      detailData,
      mode
    } = this.props.externalSourceControlModal;
    const submitData = {
      ...detailData,
      op: mode
    }
    const par ={
      sourceName: submitData.sourceName,
      sourceType: submitData.sourceType,
    }
    this.props.submitData(submitData);
  }
  
  submitForm = () => {
    this.formRef.current.submit();
  }

  _createForm = () => {
    const {
      detailData,
      submitErrorMsg
    } = this.props.externalSourceControlModal;
    this.formRef = React.createRef();
    return (
    <Form
      ref={this.formRef}
      className="ant-advanced-search-form"
      onFinish={this._submitData}
      initialValues={detailData}
    >
      <Row gutter={24}>{this._getFields()}</Row>
      {submitErrorMsg?(
        <Row gutter={24}><Col span={24}>
            <Message error list={[submitErrorMsg]} style={{ marginBottom: '3px' }} />
          </Col></Row>
        ):("")
      }
    </Form>
    );
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createChangeLogGrid = () =>{
    const { className = 'grid-wrapper' } = this.props;
    const { userChangeLogList } = this.props.externalSourceControlModal;
    const sortedRows = _.orderBy(userChangeLogList, ['createTime'], ['desc']);
    return (
      <div className={`ag-theme-balham-dark ${className}`} style={{width:'100%',height:'100%',minHeight:"300px"}}>
        <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={sortedRows}
            suppressAggFuncInHeader={true}
            animateRows={true}
            enableRangeSelection={true}
            rowSelection="single"
            onGridReady={this.onGridReady}
          />
        </div>
    )
  }

  _createAccessLogGrid = () =>{
    const { className = 'grid-wrapper' } = this.props;
    const { userAccessLogList } = this.props.externalSourceControlModal;
    const sortedRows = _.orderBy(userAccessLogList, ['createTime'], ['desc']);
    return (
      <div className={`ag-theme-balham-dark ${className}`} style={{width:'100%',height:'100%',minHeight:"300px"}}>
        <AgGridReact
            // properties
            // columnDefs={userAccessLogGridColumn}
            defaultColDef={this.state.defaultColDef}
            rowData={sortedRows}
            suppressAggFuncInHeader={true}
            animateRows={true}
            enableRangeSelection={true}
            rowSelection="single"
            onGridReady={this.onGridReady}
          />
        </div>
    )
  }

  render() {
    const {
      isOpened,
      mode,
      submitDataStatus,
      detailData
    } = this.props.externalSourceControlModal;

    const isNewMode = mode === OperationtMode.NEW;
    const isUpdateMode = mode === OperationtMode.UPDATE;
    const status = submitDataStatus;

    let modalHeaderContent = 'Source Detail';
    if (isNewMode) {
      modalHeaderContent = 'Add Source';
    } else if (isUpdateMode) {
      modalHeaderContent = 'Edit Source';
    }
    if(detailData && detailData.englishName){
      modalHeaderContent = `${modalHeaderContent}(${detailData.englishName})`
    }

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

          `}
        </style>
        <Modal key="UserManageModal" size="large" open={isOpened}>
          <Header content={modalHeaderContent} />
          <Modal.Content>
            <Tabs tabPosition="top" key="RightTabs" defaultActiveKey="1" >
                <Tabs.TabPane tab="Source Info" key="1">
                  {this._createForm()}
                </Tabs.TabPane>
                {OperationtMode.DETAIL === mode && 
                  <Tabs.TabPane tab="Change Log" key="2">
                    {this._createChangeLogGrid()}
                  </Tabs.TabPane>
                }
                {OperationtMode.DETAIL === mode && 
                  <Tabs.TabPane tab="Access Log" key="3">
                    {this._createAccessLogGrid()}
                  </Tabs.TabPane>
                }
            </Tabs>
          </Modal.Content>
          <Modal.Actions>
            {this._readOnly()?(""):
              ( {
                ERROR: (
                  <Button onClick={this.submitForm}>
                     Submit Fail - Retry?
                  </Button>
                ),
                READY: (
                  <Button onClick={this.submitForm}>
                      Submit
                  </Button>
                )
              }[status])
              }
              <Button onClick={this.props.closeExternalSourceControlModal}>
                Cancel
              </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ExternalSourceDialog;
