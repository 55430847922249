import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {

    loadAllUser: (params) => {
        const url = `${config.api.LOGIN_URL}/userManage/getAllUser`;
        return apiClient.post(url,params);
    },

    getUser: () => {
        const url = `${config.api.LOGIN_URL}/userManage/getUser`;
        return apiClient.post(url);
    },

    submitUser: (params) => {
        const url = `${config.api.AUTH_URL}/userManage/submitUser`;
        return apiClient.post(url,params);
    },

    getUserAccountChangeLog: (params) => {
        const url = `${config.api.LOGIN_URL}/userManage/getUserAccountChangeLog`;
        return apiClient.post(url,params);
    },

    getUserAccountAccessLog: (params) => {
        const url = `${config.api.AUTH_URL}/userManage/getUserAccessLog`;
        return apiClient.post(url,params);
    },

    getPmManageList: (params) => {
        const url = `${config.api.LOGIN_URL}/userManage/getPmManageList`;
        return apiClient.post(url, params);
    },

    getRoleList: (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getRoleList`;
        return apiClient.post(url, params);
    },

    getPermissionList: (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getPermissionList`;
        return apiClient.post(url, params);
    },

    getRolePermissions: (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getRolePermissions`;
        return apiClient.post(url, params);
    },

    getUserAllPermissionList: (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getUserAllPermissionList`;
        return apiClient.post(url, params);
    },

    getUserDataAccessPermissions: (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getUserDataAccessPermissions`;
        return apiClient.post(url, params);
    },

    saveUserRole: (params) => {
        const url = `${config.api.AUTH_URL}/permissionManage/saveUserRole`;
        return apiClient.post(url, params);
    },

    saveRolePermission: (params) => {
        const url = `${config.api.AUTH_URL}/permissionManage/saveRolePermission`;
        return apiClient.post(url, params);
    },

    getAllDataAccessPermissions: () => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getAllDataAccessPermissions`;
        return apiClient.post(url);
    },

    savePermissions : (params) => {
        const url = `${config.api.AUTH_URL}/permissionManage/savePermissions`;
        return apiClient.post(url, params);
    },

    getPermissions : (params) => {
        const url = `${config.api.LOGIN_URL}/permissionManage/getPermissions`;
        return apiClient.post(url, params);
    },

};

export default client;
