import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {

  loadBrokerScoreListByScore: queryParams => {
    queryParams.source = 'BACK';
    const url = `${config.api.RESEARCH_URL}/brokerScore/queryBrokerScoreListByScore`;
    return apiClient.post(url, queryParams);
  },

  loadBrokerScoreList: queryParams => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/getBrokerScoreList`;
    return apiClient.post(url, queryParams);
  },

  saveBrokerScore: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/saveBrokerScore`;
    return apiClient.post(url, params);
  },

  loadBrokerCanScoreDate: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/loadBrokerCanScoreDate`;
    return apiClient.post(url, params);
  },

  removeBrokerScore: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/removeBrokerScore`;
    return apiClient.post(url, params);
  },

  loadCorrespondenceList: params => {
    const url = `${config.api.RESEARCH_URL}/brokerScore/getCorrespondenceList`;
    return apiClient.post(url, params);
  },

};

export default client;
