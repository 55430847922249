import React, { Component } from 'react';
import {
    Modal,
    Button,
    Tree,
    PageHeader
  } from 'antd';
import SplitPane from 'react-split-pane';
import _ from 'lodash';

class UserRolePermissionsDialog extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    _closeModal = () => {
      this.props.closePortfolioPlanDialog();
    };

    _save = () => {
      this.props.onSubmit();
    }

    _getCheckKeys = (permissions, permissionsCheckKeys) => {
      if(_.isEmpty(permissions)) return[];
      if(_.isEmpty(permissionsCheckKeys)) permissionsCheckKeys = [];
      const appPermissions = [];
      const checkKeysMap = {};
      permissionsCheckKeys.forEach(r => {checkKeysMap[r] =r});
      for (let i=0;i<permissions.length;i++){ 
        const permission = permissions[i];
        for (let j=0;j<permission.children.length;j++){ 
          const key = permission.children[j].key;
          if(checkKeysMap[key]) {
            appPermissions.push(permission.key);
            break;
          }
        }
      }
      permissionsCheckKeys.push(...appPermissions);
      return permissionsCheckKeys;
    }

    render() {
        const { 
          closeModal, 
          modalData, 
          onDataPermissionTreeCheck,
          onFundPermissionTreeCheck,
          onRoleTreeCheck,
         } = this.props;
        const { 
          roles,
          roleCheckedKeys,
          permissions,
          permissionsCheckKeys,
          dataPermissionTree,
          dataPermissionCheckKeys,
          englishName,
          isOpened } = modalData;
          const checkKeys = this._getCheckKeys(permissions,
            permissionsCheckKeys);
        return (
            <Modal
            size="large"
            visible={isOpened}
            title={`Manage User Permissions(${englishName})`}
            width={1400}
            style={{ height: 650 }}
            footer={[
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this._save();
                  }}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            ]}
            closable={false}
          >
            <SplitPane
              split="vertical"
              defaultSize="40%"
              style={{ position: 'relative'}}
            >
              <div style={{height:'600px'}}>
                <PageHeader
                  className="site-page-header"
                  title="User Role"
                />
                <div style={{height:'88%', width: '100%', overflow:'auto'}}>
                    <Tree
                      checkable
                      autoExpandParent={true}
                      checkedKeys={roleCheckedKeys}
                      onCheck={expandedKeysValue => {
                        onRoleTreeCheck(expandedKeysValue, true)
                      }}
                      treeData={roles}
                    />
                </div>
              </div>
              <SplitPane
                split="vertical"
                defaultSize="50%"
                style={{ position: 'relative'}}
              >
                <div style={{height:'600px'}}>
                  <PageHeader
                    className="site-page-header"
                    title="Func Permission"
                  />
                  <div style={{height:'500px', overflow:'auto'}}>
                    <Tree
                      checkable
                      autoExpandParent={true}
                      checkedKeys={checkKeys}
                      treeData={permissions}
                      checkStrictly={true}
                      onCheck={expandedKeysValue => {
                        onFundPermissionTreeCheck(expandedKeysValue, true)
                      }}
                    />
                  </div>
                </div>
                <div style={{height:'600px'}}>
                  <PageHeader
                    className="site-page-header"
                    title="Data Permission"
                  />
                  <div style={{height:'500px', overflow:'auto'}}>
                    <Tree
                      checkable
                      autoExpandParent={true}
                      checkedKeys={dataPermissionCheckKeys}
                      treeData={dataPermissionTree}
                      onCheck={expandedKeysValue => {
                        onDataPermissionTreeCheck(expandedKeysValue, true)
                      }}
                    />
                  </div>
                </div>
              </SplitPane>
            </SplitPane>
          </Modal>
        );
    }
}

export default UserRolePermissionsDialog;