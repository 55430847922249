import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {


    loadExternalSource: (params) => {
        const url = `${config.api.EXTERNAL_URL}/partnership/api/v1/marking-values/external-source-list?externalType=${params.externalType}`;
        return apiClient.get(url);
    },

    submitExternalSource: (params) => {
        const url = `${config.api.EXTERNAL_URL}/partnership/api/v1/marking-values/external-source-add`;
        return apiClient.get(url+params,params);
    },
};

export default client;
