import React, { Component } from 'react';
import OperationtMode from 'common/utils/OperationtMode';
import 'moment/locale/zh-cn';
import moment from 'moment';
import { DatePicker, Row, Col, Button } from 'antd';
import BrokerDialogGrid from './BrokerDialogGrid';
import { Header, Modal, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import client from '../../api/client';

class BrokerSaveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDayOffset: 7,
      endDayOffset: 7
    };
  }

  votingMonthChange = (date, dataString) => {
    this.props.changeBrokerScoreControlModalInput({
      name: 'votingMonth',
      value: date
    });
    this.props.loadBrokerScoreListByMonth(
      this.props.pmName,
      dataString
    );
  };

  componentDidMount() {
    this._initData();
  }

  _initData = () => {
    client
      .loadBrokerCanScoreDate()
      .then(resp => {
        const data = resp.data;
        if (data) {
          this.setState({
            startDayOffset: data.start,
            endDayOffset: data.end
          });
        }
      })
      .catch(err => {});
  };

  saveBrokerDetail = () => {
    const {
      brokerScoreListForDetail,
      brokerScoreControlModal,
      brokerQueryParams
    } = this.props;
    const detailArr = [];
    for (var detail in brokerScoreListForDetail) {
      detailArr.push(...brokerScoreListForDetail[detail]);
    }
    detailArr.forEach(item => {
      const detailList = item.detailList;
      let totalScore = 0;
      detailList.forEach(ele => {
        ele.score = item[ele.code] ? item[ele.code] : 0;
        totalScore += ele.score;
      });
      item.totalScore = totalScore;
    });
    const { votingMonth } = brokerScoreControlModal;
    const params = {
      votingMonth: `${votingMonth.year()}-Q${votingMonth.quarter()}`,
      correspondenceVOList: brokerScoreControlModal['weightMap'],
      scoreList: detailArr
    };
    this.props.saveBrokerScore(params, brokerQueryParams);
  };

  checkNumberValue = value => {
    const reg = new RegExp('^[0-5]$');
    var flag = true;
    if (!reg.test(value)) {
      flag = false;
    }
    return flag;
  };

  addOrRemoveErrorMsg = msgList => {
    const { errorMsgMap } = this.props.brokerScoreControlModal;
    var refreshFlag = false;
    msgList.forEach(element => {
      const op = element['op'];
      const key = element['key'];
      const msg = element['value'];
      if (op === 'Add') {
        errorMsgMap[key] = msg;
        refreshFlag = true;
      } else if (op === 'Remove') {
        if (errorMsgMap[key]) {
          delete errorMsgMap[key];
          refreshFlag = true;
        }
      }
    });

    if (refreshFlag) {
      this.props.changeBrokerScoreControlModalInput({
        name: 'errorMsgMap',
        value: errorMsgMap
      });
    }
  };

  showSubmitButton = () => {
    const { startDayOffset, endDayOffset } = this.state;
    const { votingMonth } = this.props.brokerScoreControlModal;
    if (!votingMonth) {
      return false;
    }
    const currentTime = moment().format('YYYY-MM-DD');
    const startMonthEndDay = votingMonth.clone().endOf('quarter');
    const currentMonthLast7Day = startMonthEndDay
      .clone()
      .subtract(startDayOffset, 'days')
      .format('YYYY-MM-DD');
    const nextMonthFirst7Day = startMonthEndDay
      .clone()
      .add(endDayOffset, 'days')
      .format('YYYY-MM-DD');
    const currentMonth = startMonthEndDay.format('YYYY-MM');
    const votingMonthStr = `${votingMonth.year()}-Q${votingMonth.quarter()}`;
    this.votingMonthStr = votingMonthStr;
    this.currentMonthLast7Day = currentMonthLast7Day;
    this.nextMonthFirst7Day = nextMonthFirst7Day;
    if (
      currentTime >= currentMonthLast7Day &&
      currentTime <= nextMonthFirst7Day &&
      currentMonth === votingMonthStr
    ) {
      return true;
    }
    return false;
  };

  dataIsEmpty = dataArr => {
    if (dataArr && dataArr.length > 0) {
      return false;
    }
    return true;
  };

  _initDetailData = brokerScoreListForDetail => {
    _.map(brokerScoreListForDetail, (value, key) => {
      if (value) {
        value.forEach(item => {
          const detailList = item.detailList;
          detailList.forEach(ele => {
            const code = ele['code'];
            item[code] = ele['score'];
          });
        });
      }
    });
  };

  render() {
    const {
      isOpened,
      mode,
      weightMapLoaded,
      votingMonth,
    } = this.props.brokerScoreControlModal;

    const { isLoaded, brokerScoreListForDetail } = this.props;
    this.data = brokerScoreListForDetail;
    const isNewMode = mode === OperationtMode.NEW;
    const isUpdateMode = mode === OperationtMode.UPDATE;

    let modalHeaderContent = 'Broker';
    if (isNewMode) {
      modalHeaderContent = 'Broker Score';
    } else if (isUpdateMode) {
      modalHeaderContent = 'Edit Broker';
    }

    return (
      <div id="brokerDialogTab">
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

            #brokerDialogTab .ant-calendar-picker {
              width: 100%;
            }

            #brokerDialogTab .ant-input {
              height: 100%;
            }

            #brokerDialogTab .ant-picker {
              margin-left:10px;
            }

            #brokerDialogTab .buttonDiv {
              display: flex;
              justify-content: flex-end;
            }

            #brokerDialogTab .ant-tabs-bar {
              margin: 0 0 8px 0;
              color: black;
            }

            #brokerDialogTab .ant-tabs-nav .ant-tabs-tab-active {
              /* background-color: #FF9E28; */
              color: black;
            }
            
            #brokerDetailDialog {
              width :600px;
            }
          `}
        </style>
        <Modal id="brokerDetailDialog" size="small" open={isOpened}>
          <Header content={modalHeaderContent} />
          <Modal.Content>
            <Row>
              <Col span={18}>
                <label>Voting Month:</label>
                <DatePicker
                  picker="quarter"
                  value={votingMonth}
                  allowClear={false}
                  onChange={this.votingMonthChange}
                />
              </Col>
            </Row>
            {!isLoaded || !weightMapLoaded ? (
              <Loader active inverted content="Loading..." />
            ) : (
              <div id="brokerDialogTab" style={{width: '100%', height: '100%'}}>
                  {this.dataIsEmpty(this.data['BACK']) ? (
                    ''
                  ) : (
                    <div style={{marginTop: '10px'}}>
                      <BrokerDialogGrid
                        {...this.props}
                        data={this.data['BACK']}
                        checkFiledByFiledName={this.checkFiledByFiledName}
                      />
                    </div>  
                  )}
                <div>
                  <p style={{ marginBottom: '5px' }}>
                    <b>Note: </b>
                  </p>
                  <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <b>{'1. 5 is the highest score, 1 is the lowest.'}</b>
                  </p>
                  
                </div>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.saveBrokerDetail} disabled={!isLoaded}>
                Submit
            </Button>
            <Button onClick={this.props.resetBrokerDetailControlModal}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default BrokerSaveDialog;
