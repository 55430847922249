import {
    INIT_HOMEPAGE
} from './homePageConstants';

export function initHomepage(payload) {
    return {
      type: INIT_HOMEPAGE,
      payload: payload
    };
}


