
export const INITIATE_LOADER_STATUS = 'INITIATE_LOADER_STATUS';
export const INITIATE_BROKER_LOADER_STATUS = 'INITIATE_BROKER_LOADER_STATUS';
export const LOAD_EXTERNAL_SOURCE_SUCCESS = 'LOAD_EXTERNAL_SOURCE_SUCCESS';
export const LOAD_EXTERNAL_SOURCE_FAILURE = 'LOAD_EXTERNAL_SOURCE_FAILURE';
export const LOAD_BROKER_SOURCE_SUCCESS = 'LOAD_BROKER_SOURCE_SUCCESS';
export const LOAD_BROKER_SOURCE_FAILURE = 'LOAD_BROKER_SOURCE_FAILURE';
export const CHANGE_EXTERNAL_SOURCE_MODAL_INPUT = 'CHANGE_EXTERNAL_SOURCE_MODAL_INPUT';
export const OPEN_EXTERNAL_SOURCE_MODAL_INPUT = 'OPEN_EXTERNAL_SOURCE_MODAL_INPUT';
export const CLOSE_EXTERNAL_SOURCE_MODAL_INPUT = 'CLOSE_EXTERNAL_SOURCE_MODAL_INPUT';
export const SUBMIT_EXTERNAL_SOURCE_FAILURE = 'SUBMIT_EXTERNAL_SOURCE_FAILURE';
export const SUBMIT_EXTERNAL_SOURCE_SUCCESS = 'SUBMIT_UEXTERNAL_SOURCE_SUCCESS';
