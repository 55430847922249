import React, { Component } from 'react';
import ApplicationManageGrid from './ApplicationManageGrid'
import RoleApplicationManageDialog from './RoleApplicationManageDialog'

class ApplicationManageDashboard extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
          <ApplicationManageGrid 
            {...this.props}
          />
          <RoleApplicationManageDialog 
            {...this.props}
          />
      </div>
    );
  }
}

export default ApplicationManageDashboard;
